<script>
import TwCard from '@/components/generic/tw-card.vue'
import TwAmount from '@/components/generic/forms/tw-amount.vue'
import TwButton from '@/components/generic/tw-button.vue'
import { useFundraiserStore } from '@/stores/fundraiser.js'
import { useFundraiserPublicStore } from '@/stores/fundraiserPublic.js'
import TwModal from '@/components/generic/modal/tw-modal.vue'
import { loadStripe } from '@stripe/stripe-js'
import TwTransitionContainer from '@/components/generic/tw-transition-container.vue'
import TwNotification from '@/components/generic/tw-notification.vue'
import TwTransitionListContainer from '@/components/generic/tw-transition-list-container.vue'
import TwCheckbox from '@/components/generic/forms/tw-checkbox.vue'
import DonationAppFeeChangeModal from '@/components/fundraisers/donation-app-fee-change-modal.vue'
import TwWysiwyg from '@/components/generic/forms/tw-wysiwyg.vue'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwSelect from '@/components/generic/forms/tw-select.vue'
export default {
  name: 'FundraiserDonate',
  components: { TwSelect, TwValidationErrors, TwWysiwyg, DonationAppFeeChangeModal, TwCheckbox, TwTransitionListContainer, TwNotification, TwTransitionContainer, TwModal, TwButton, TwAmount, TwCard },
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ValidationErrorMessage: null,
      DonationAmount: null,
      DonationAppFeePercentage: 8,
      DonationGatewayActive: false,
      DonationCompleted: false,
      DonationAnonymousName: false,
      DonationAnonymousAmount: false,
      DonationShowCommentForm: false,
      DonationComment: null,
      DonationFundraiserChild: null,
      StripeCheckoutObject: null
    }
  },
  computed: {
    donationAppFee() {
      if(!this.DonationAmount) return 0

      const feeLocal = Math.ceil(this.DonationAmount * (this.DonationAppFeePercentage / 100))
      if(feeLocal < 4) return 4;
      if(feeLocal > 12) return 12;
      return feeLocal
    },
    donationTotalAmount() {
      if(!this.DonationAmount) return 0
      return parseInt(this.DonationAmount) + parseInt(this.donationAppFee)
    },
    fundraiserChildren() {
      return [
        {id: this.fundraiser.id, title: 'Select a support of this fundraiser:'}
      ].concat(useFundraiserPublicStore().getItemsByParentId(this.fundraiser.id))
    }
  },
  methods: {
    redirectToFundraiserChild(fundraiserId) {
      console.log(fundraiserId)
      const fundraiserChild = this.fundraiserChildren.find(fundraiser => fundraiser.id === fundraiserId)
      this.$router.push({name: 'FundraiserPublicPage', params: {fundraiserId: fundraiserChild.id, slug: fundraiserChild.slug}})
    },
    async fetchClientSecret() {
      try {
        this.ValidationErrorMessage = null
        this.Loading = true
        const stripeCheckoutSession = await useFundraiserPublicStore().startDonation({
          fundraiser_id: this.fundraiser.id,
          app_fee: this.donationAppFee,
          amount: this.donationTotalAmount,
          anonymous_name: this.DonationAnonymousName,
          anonymous_amount: this.DonationAnonymousAmount,
          comment: this.DonationComment
        })
        return stripeCheckoutSession.client_secret
      } catch (e) {
        console.error(e)
        this.Loading = false
        if (e.response) this.ValidationErrorMessage = e.response.data
        throw e
      }
    },
    async startDonation() {
      const stripe = await loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: this.fundraiser.payment_service.account_id,
      })
      this.DonationGatewayActive = true

      this.StripeCheckoutObject = await stripe.initEmbeddedCheckout({
        fetchClientSecret: this.fetchClientSecret,
        onComplete: async () => {
          await this.StripeCheckoutObject.destroy()
          this.DonationGatewayActive = false
          this.DonationCompleted = true
          this.DonationAmount = null
          this.DonationAnonymousName = false
          this.DonationAnonymousAmount = false
          this.DonationShowCommentForm = false
          this.DonationComment = null
          this.StripeCheckoutObject = null
          this.$refs.notification.show()
        }
      });
      // Mount Checkout
      this.StripeCheckoutObject.mount('#checkout');
    }
  }
}
</script>

<template>
  <tw-card>
    <tw-transition-container>
      <div v-if="!DonationCompleted" class="text-2xl font-semibold">
        Donate for this cause:
      </div>
      <div v-if="DonationCompleted">
        <div class="text-3xl my-2 bg-green-50 rounded-3xl font-bold text-green-500 text-center p-10">
          <p>Donation completed successfully!</p>
          <div class="flex justify-center mt-4">
            <tw-button size="lg" @click="DonationCompleted = false">
              Make Another Donation
            </tw-button>
          </div>
        </div>
      </div>
    </tw-transition-container>
    <tw-transition-list-container>
      <div v-if="!DonationGatewayActive && !DonationCompleted" class="grid grid-cols-1 lg:grid-cols-8 lg:gap-4">
        <div class="col-span-8 md:col-span-5">
          <tw-amount v-model="DonationAmount" size="lg" @keydown.enter="startDonation" />
          <div class="text-sm text-gray-500">
            A <span v-if="donationAppFee > 0" class="italic">${{ donationAppFee }}</span> app fee will be added to your donation to help process these funds.
            <tw-button size="sm" variant="link" @click="$refs.DonationAppFeeChangeModal.open()">
              Change
            </tw-button>
          </div>
        </div>
        <div class="col-span-12 md:col-span-3">
          <tw-button variant="primary-grouprev" size="lg" class="mt-2.5 uppercase" block @click="startDonation">
            Give Now <span v-if="donationTotalAmount > 0" class="font-bold"> $ {{ donationTotalAmount }} </span>
          </tw-button>
        </div>
      </div>
      <div v-if="fundraiser.settings && fundraiser.settings.enable_supporters && !DonationGatewayActive && !DonationCompleted && fundraiserChildren.length > 1" class="mt-2">
        <tw-select :options="fundraiserChildren" value-key="id" label-key="title" @update:model-value="redirectToFundraiserChild" />
      </div>
      <div v-if="!DonationGatewayActive && !DonationCompleted" class="mt-2">
        <tw-checkbox v-model="DonationAnonymousName">
          Make my name anonymous
        </tw-checkbox>
        <tw-checkbox v-model="DonationAnonymousAmount">
          Make my donation amount anonymous
        </tw-checkbox>
        <tw-checkbox v-model="DonationShowCommentForm">
          Add a personal comment to the donation
        </tw-checkbox>
        <tw-card v-if="DonationShowCommentForm" class="mt-2 bg-white">
          <tw-wysiwyg v-model="DonationComment" label="Write your personal message:" class="w-full p-2" placeholder="Add a personal message to the donation" />
        </tw-card>
      </div>
    </tw-transition-list-container>
    <tw-validation-errors :validation-result-error="ValidationErrorMessage" />
    <tw-transition-container>
      <div v-if="DonationGatewayActive" id="checkout" />
    </tw-transition-container>
    <donation-app-fee-change-modal ref="DonationAppFeeChangeModal" v-model="DonationAppFeePercentage" />

    <tw-notification ref="notification">
      Donation completed successfully!
    </tw-notification>
  </tw-card>
</template>

<style scoped>

</style>
