<script>
import TwBadge from '@/components/generic/tw-badge.vue'
import TwUploadedImage from '@/components/generic/uploads/tw-uploaded-image.vue'
import TwListGroup from '@/components/generic/list-group/tw-list-group.vue'
import TwListGroupItem from '@/components/generic/list-group/tw-list-group-item.vue'
import TwButton from '@/components/generic/tw-button.vue'
import TwGrid from '@/components/generic/tw-grid.vue'

export default {
  name: 'FundraiserUpdatesViewPublic',
  components: { TwGrid, TwButton, TwListGroupItem, TwListGroup, TwUploadedImage, TwBadge },
  props: {
    fundraiserUpdates: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
  <tw-list-group>
    <tw-list-group-item v-for="fundraiserUpdate in fundraiserUpdates" :key="'fundraiser-update-key-' + fundraiserUpdate.id">
      <div class="flex">
        <div>
          <p class="mb-4" v-html="fundraiserUpdate.message" />
          <span class="text-sm"> Written by: <tw-badge variant="blue">{{ fundraiserUpdate.user.name }}</tw-badge> </span>
          <tw-grid v-if="fundraiserUpdate.photos">
            <tw-uploaded-image v-for="photo in fundraiserUpdate.photos" :key="'fundraiser-update-key' + photo.id" :upload="photo" />
          </tw-grid>
        </div>
      </div>
    </tw-list-group-item>
  </tw-list-group>
</template>

<style scoped>

</style>
