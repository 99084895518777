<script>
export default {
  name: 'TwGrid'
}
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <slot />
  </div>
</template>

<style scoped>

</style>
