import { defineStore } from 'pinia'
export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
  }),
  actions: {
    set_user(user) {
      this.user = user
    },
    async attempt_user() {
      try {
        const { data } = await axios.get('/api/v1/me')
        this.user = data.data
        return data.data
      }
      catch(e) {
        this.user = null
      }
    },
    async logout() {
      return axios.post('/logout')
        .then((response) => {
          this.router.push('/')
          this.$reset()
        })
        .catch((error) => {
          console.error(error)
          throw error
        })
    },
    async login(payload) {
      await axios.get('/sanctum/csrf-cookie');
      const loginResponse = await axios.post('/login', payload)

      if (loginResponse?.status === 200 && loginResponse.data?.two_factor) {
        await this.router.push({ name: 'TwoFactorChallenge' })
        return
      }
      const attemptUserResponse = await this.attempt_user()
      if (this.user && payload.redirect_to) {
        console.log('Trying to redirect to', payload.redirect_to)
        await this.router.push(payload.redirect_to)
      }
    }
  },
  getters: {
    currentUser: (state) => state.user,
  },
})
