<script>
export default {
  name: 'TwCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    labelSecondary: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    }
  },
  emits: ['update:modelValue']
}
</script>

<template>
  <fieldset class="mb-2">
    <legend class="sr-only">
      {{ label }}
    </legend>
    <div class="space-y-5">
      <div class="relative flex items-start">
        <div class="flex h-6 items-center">
          <input :id="id" :disabled="readonly" :value="modelValue" :checked="modelValue === true" :aria-describedby="id + '-description'" :name="id" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" @input="$emit('update:modelValue', $event.target.checked)">
        </div>
        <div class="ml-3 text-sm leading-6">
          <label :for="id" class="font-medium text-gray-900"> <slot> {{ label }} </slot> </label>
          <p v-if="labelSecondary" id="id-description" class="text-gray-500">
            {{ labelSecondary }}
          </p>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<style scoped>

</style>
