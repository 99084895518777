import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import VueSocialSharing from 'vue-social-sharing'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './plugins/fontawesome.js'

import router from '@/router';
import App from '@/layouts/App.vue'
import filters from '@/filters/index.js'
import '../css/app.css';
import './plugins/axios.js';

import './plugins/fontawesome.js'

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})
const app = createApp(App)


// await useAuthStore().attempt_user()

app.use(pinia)
  .use(router)
  .component('icon',FontAwesomeIcon)
  .use(VueSocialSharing)
  .mount('#app');



app.config.globalProperties.$filters = filters.reduce((obj, filter) => {
  obj[filter.name] = filter.execute
  return obj
}, {})

