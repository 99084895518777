export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    pill: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      default: null
    }
  },
  computed: {
    shouldBeComponent() {
      if(this.to) {
        return 'router-link';
      } else {
        return 'button';
      }
    },
    twClasses() {
      return [
        'btn',
        'btn-variant-' + this.variant,
        'btn-size-' + this.size,
        {'btn-pill': this.pill},
        {'btn-block': this.block}
      ];
    },
    loadingClasses() {
      return [
        'animate-spin',
        {'h-4 w-4' : this.size === 'default'},
        {'h-3 w-3' : this.size === 'sm'},
        {'h-6 w-6' : this.size === 'lg'},
        {'h-7 w-7' : this.size === 'xl'},
        'transition-all',
        'ease-in-out',
        'duration-500',
        {'text-black': ['secondary'].indexOf(this.variant) > -1},
        {'text-blue': ['primary'].indexOf(this.variant) > -1},
        {'text-white': ['warning','danger','info'].indexOf(this.variant) > -1}
      ];
    }
  }
}
