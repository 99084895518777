<script>
import TwButton from '@/components/generic/tw-button.vue'

export default {
  name: 'TwUploadedImage',
  components: { TwButton },
  props: {
    upload: {
      type: Object,
      required: true
    },
    loadingDeleting: {
      type: Boolean,
      default: false
    },
    deleteable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['delete']
}
</script>

<template>
  <div class="overflow-hidden rounded-lg bg-white shadow max-h-56">
    <div class="p-2 sm:p-2 h-36 flex justify-center">
      <!-- Content goes here -->
      <a target="_blank" :href="upload.url"><img :src="upload.url" class="h-full object-cover object-center rounded-lg"></a>
    </div>
    <div v-if="deleteable" class="px-4 py-4 sm:px-6 mt-2 flex justify-center">
      <!-- Content goes here -->
      <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
      <tw-button :loading="loadingDeleting" size="sm" variant="danger" @click="$emit('delete', upload)">
        Delete
      </tw-button>
    </div>
  </div>
</template>

<style scoped>

</style>
