<template>
  <TransitionRoot as="template" :show="modalIsOpen">
    <Dialog class="relative z-10" @close="closedFromModal">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen max-h-screen">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 max-h-screen">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-5xl">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left max-w-5xl">
                  <DialogTitle v-if="title || $slots.title " as="h3" class="text-base font-semibold leading-6 text-gray-900 pl-1">
                    <slot name="title">
                      {{ title }}
                    </slot>
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-sm text-gray-500 mb-2 mt-4 max-h-screen overflow-y-auto pr-4 pl-1" style="max-height: 30rem;">
                      <slot name="default">
                        Modal Body
                      </slot>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!noFooter" class="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6">
                <tw-button :loading="loading" :disabled="okDisabled" :variant="variant" class="ml-2" @click="clickedOk">
                  <slot name="ok">
                    {{ okText }}
                  </slot>
                </tw-button>
                <tw-button variant="light" class="ml-2" @click="clickedCancel">
                  Cancel
                </tw-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import TwButton from '@/components/generic/tw-button.vue'
const emit = defineEmits(['ok', 'cancel','opened','closed'])
defineProps({
  modalId: {
    type: String,
    required: false,
    default: function () {
      return 'tw-modal-' + Math.random().toString(36).substring(2, 12)
    }
  },
  loading: {
    type: Boolean,
    default: false
  },
  okDisabled: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: 'primary'
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'default'
  },
  okText: {
    type: String,
    default: 'Ok'
  },
  noFooter: {
    type: Boolean,
    default: false
  }
})


const modalIsOpen = ref(false)
const open = () => {
  modalIsOpen.value = true
  setTimeout(() => {
    emit('opened')
  }, 500)
}
const close = () => {
  modalIsOpen.value = false
}

const closedFromModal = () => {
  modalIsOpen.value = false
  emit('closed')
}

const clickedOk = () => {
  // we need to emit an event
  emit('ok')
}

const clickedCancel = () => {
  modalIsOpen.value = false
  emit('cancel')
}

defineExpose({
  open,
  close
})


</script>
