<script>
export default {
  name: 'TwListGroup'
}
</script>

<template>
  <ul role="list" class="divide-y divide-gray-400">
    <slot />
  </ul>
</template>

<style scoped>

</style>
