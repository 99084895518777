<script>
export default {
  name: 'TwCard',
  props: {
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    containerClasses() {
      return {
        'px-4 py-5 sm:p-4': this.size === 'md',
        'px-3 py-2 sm:p-2': this.size === 'sm',
        'px-6 py-7 sm:p-6': this.size === 'lg'
      }
    }
  }
}
</script>

<template>
  <div :class="['rounded-lg bg-gray-50 shadow mb-2', containerClasses]">
    <slot />
  </div>
</template>

<style scoped>

</style>
