<script>
import BlazeSlider from 'blaze-slider'
import 'blaze-slider/dist/blaze.css'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/solid'
import TwCard from '@/components/generic/tw-card.vue'

export default {
  name: 'FundraiserSlideshow',
  components: { TwCard, ArrowRightIcon, ArrowLeftIcon},
  props: {
    slideshow: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Slider: null,
      Slide: 1
    }
  },
  mounted() {
    this.Slider = new BlazeSlider(document.querySelector('#fundraiser-slideshow-' + this.slideshow.id), {
      all: {
        slidesToShow: 1,
      },
    })
    this.Slider.onSlide((pageIndex, firstSlideIndex, lastSlideIndex) => {
      this.Slide = pageIndex + 1
    })
  }
}
</script>

<template>
  <tw-card :id="'fundraiser-slideshow-' + slideshow.id" class="blaze-slider">
    <div class="blaze-container">
      <div class="blaze-track-container">
        <div class="blaze-track h-96">
          <div v-for="slide in slideshow.slides" :key="'slideshow-slide-key-' + slideshow.id + '-' + slide.id" class="flex justify-center">
            <img class="object-scale-down hover:object-fill" alt="Slide" :src="slide.upload.url">
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <button class="blaze-prev" aria-label="Go to previous slide">
          <ArrowLeftIcon class="size-10" />
        </button>
        <div class="pagination">
          {{ Slide }} / {{ slideshow.slides.length }}
        </div>
        <button class="blaze-next" aria-label="Go to next slide">
          <ArrowRightIcon class="size-10" />
        </button>
      </div>
    </div>
  </tw-card>
</template>

<style scoped>

</style>
