<script>

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  name: 'TwWysiwyg',
  components: { QuillEditor },
  props: {
    modelValue: {
      type: [String,Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script>

<template>
  <div class="w-full mb-2">
    <label v-if="label || $slots.label" :for="id" class="block text-sm font-medium leading-6 text-gray-900 mb-1"> <slot name="label">{{ label }}</slot> </label>
    <QuillEditor :id="id" content-type="html" :name="id" :readonly="disabled" :autocomplete="id" :content="modelValue" :required="required" theme="snow" :placeholder="placeholder" @update:content="$emit('update:modelValue', $event)" />
    <slot name="under" class="text-sm text-gray-500 mt-1" />
  </div>
</template>

<style scoped>

</style>
