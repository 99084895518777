<script>
import TwInput from '@/components/generic/forms/tw-input.vue'
import { useUsersStore } from '@/stores/users.js'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwPassword from '@/components/generic/forms/tw-password.vue'
import TwButton from '@/components/generic/tw-button.vue'

export default {
  name: 'RegisterUserAccount',
  components: { TwButton, TwPassword, TwValidationErrors, TwInput },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        email_confirm: null,
        password: null,
        password_confirmation: null,
      })
    }
  },
  emits: ['update:modelValue', 'userRegistered'],
  data() {
    return {
      User: {
        ...this.modelValue
      },
      Error: null,
      LoadingRegisteringUser: false
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        console.log('coming from above')
        this.User = { ...newVal };
      },
      deep: true
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('update:modelValue', this.User);
    },
    async registerUser() {
      try {
        this.Error = null
        this.LoadingRegisteringUser = true
        const user = await useUsersStore().registerUser({
          name: `${this.User.first_name} ${this.User.last_name}`,
          email: this.User.email,
          password: this.User.password,
          password_confirmation: this.User.password_confirmation
        })
        this.LoadingRegisteringUser = false
        this.$emit('userRegistered', {
          id: user.id,
          name: `${this.User.first_name} ${this.User.last_name}`,
          email: this.User.email,
          password: this.User.password
        })
        return user
      } catch (e) {
        console.error(e.response.data)
        this.Error = e.response.data
        this.LoadingRegisteringUser = false
        throw e
      }
    },
    validationErrorsClose() {
      this.Error = null
    }
  }
}
</script>

<template>
  <fieldset @input="emitUpdate">
    <div class="grid grid-cols-2 gap-2">
      <tw-input v-model="User.first_name" required label="First Name" />
      <tw-input v-model="User.last_name" required label="Last Name" />
    </div>
    <p> This will become your authentication information </p>
    <div class="grid grid-cols-2 gap-2">
      <tw-input v-model="User.email" required label="Email" />
      <tw-input v-model="User.email_confirm" required label="Confirm Email" />
    </div>
    <div class="grid grid-cols-2 gap-2">
      <tw-password v-model="User.password" required label="Password" />
      <tw-password v-model="User.password_confirmation" required label="Confirm Password" />
    </div>
    <tw-validation-errors v-if="Error" :validation-result-error="Error" @close="validationErrorsClose" />
    <div class="flex justify-end">
      <tw-button :loading="LoadingRegisteringUser" variant="primary" @click="registerUser">
        Finish Registration
      </tw-button>
    </div>
  </fieldset>
</template>

<style scoped>

</style>
