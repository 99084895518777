import axios from 'axios';
import router from '@/router/index.js'
import { useAuthStore } from '@/stores/auth.js'

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;
window.axios.defaults.withXSRFToken = true;

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.error(error)
  if (error.response.status === 401 || error.response.status === 419) {
    if (error.response.data.message === 'CSRF token mismatch.') return
    if (error.response.data.message === 'Unauthenticated.') return
    useAuthStore().logout()
      .catch((error) => { console.error(error) })
    router.push({ name: 'Login' })
  } else if (error.response.status === 403) {
    router.push({ name: 'AccessDenied' })
  } else if (error.response.status === 423) {
    router.push({ name: 'ConfirmPassword' })
  }
  return Promise.reject(error);
});
