<script>
export default {
  name: 'TwAmount',
  props: {
    modelValue: {
      type: [String,Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    },
    required: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue', 'update'],
  computed: {
    textSize() {
      return [
        {'text-sm': !this.size},
        {'text-2xl': this.size === 'lg'},
        {'text-sm': this.size === 'sm'},
      ]
    },
    inputClasses() {
      return [
        'block w-full rounded-md border-0 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600',
        {'pl-7 py-1.5 text-sm': !this.size},
        {'pl-10 py-3 text-xl': this.size === 'lg'},

        {'': this.size === 'sm'}, //
      ]
    },

  }
}
</script>

<template>
  <div class="w-full mb-2">
    <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="relative mt-2 rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span :class="['text-gray-500', ...textSize]">$</span>
      </div>
      <input :id="id" :required="required" :value="modelValue" type="number" :name="id" :autocomplete="id" :class="inputClasses" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)">
      <div class="absolute inset-y-0 right-0 flex items-center">
        <label for="currency" class="sr-only">Currency</label>
        <select id="currency" name="currency" class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
          <option>USD</option>
        </select>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
