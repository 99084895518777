<script>
import TwContainer from '@/components/generic/tw-container.vue'
import TwTransitionContainer from '@/components/generic/tw-transition-container.vue'
import FundraiserSupporters from '@/components/fundraisers/fundraiser-supporters.vue'
import FundraiserUpdatesViewPublic from '@/components/fundraisers/fundraiser-updates-view-public.vue'
import FundraiserTeams from '@/components/fundraisers/fundraiser-tabs/fundraiser-teams.vue'
import TwSelectDropdown from '@/components/generic/tw-select-dropdown.vue'

export default {
  name: 'FundraiserTabs',
  components: { TwSelectDropdown, FundraiserTeams, FundraiserUpdatesViewPublic, FundraiserSupporters, TwTransitionContainer, TwContainer },
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ActiveTab: 'story'
    }
  },
  computed: {
    tabs() {
      const tabs = [
        { name: 'Story', slug: 'story', current: this.ActiveTab === 'story'},
      ]
      if(this.fundraiser.cached.donations_confirmed_count) {
        tabs.push({ name: 'Supporters', slug: 'supporters', current: this.ActiveTab === 'supporters', badge: this.fundraiser.cached.donations_confirmed_count?? null })
      }
      if(this.fundraiser.cached.fundraiser_updates_count > 0) {
        tabs.push({ name: 'Updates', slug: 'updates', current: this.ActiveTab === 'updates', badge: this.fundraiser.cached.fundraiser_updates_count ?? null })
      }
      if(this.fundraiser.parent && this.fundraiser.parent.settings && this.fundraiser.parent.settings.enable_teams && this.fundraiser.team) {
        // we add the team section
        tabs.push({ name: 'Team', slug: 'team', current: this.ActiveTab === 'team', badge: this.fundraiser.cached.fundraiser_team_members_donations ?? null })
      }
      else if(this.fundraiser.settings && this.fundraiser.settings.enable_teams) {
        tabs.push({ name: 'Teams', slug: 'teams', current: this.ActiveTab === 'teams', badge: this.fundraiser.cached.fundraiser_teams_count ?? null })
      }
      return tabs
    }
  }
}
</script>

<template>
  <div>
    <div class="md:hidden">
      <label for="tabs" class="sr-only">Select a section</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <tw-select-dropdown v-model="ActiveTab" :options="tabs" label-key="name" value-key="slug" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" />
    </div>
    <div class="hidden md:block">
      <nav class="flex space-x-4" aria-label="Tabs">
        <button v-for="tab in tabs" :key="tab.name" :class="[tab.current ? 'bg-orange-200 text-orange-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined" @click="ActiveTab = tab.slug">
          {{ tab.name }} <span v-if="tab.badge" class="bg-blue-500 text-white rounded-full px-2">{{ tab.badge }}</span>
        </button>
      </nav>
    </div>
    <tw-transition-container class="mt-4">
      <div v-if="ActiveTab === 'story'">
        <p v-html="fundraiser.long_description" />
      </div>
      <div v-else-if="ActiveTab === 'supporters'">
        <fundraiser-supporters :fundraiser="fundraiser" />
      </div>
      <div v-else-if="ActiveTab === 'updates'">
        <fundraiser-updates-view-public :fundraiser-updates="fundraiser.fundraiser_updates" />
      </div>
      <div v-else-if="ActiveTab === 'teams'">
        <fundraiser-teams :fundraiser="fundraiser" :teams="fundraiser.teams" />
      </div>
      <div v-else-if="ActiveTab === 'team'">
        <fundraiser-teams :fundraiser="fundraiser" :teams="[fundraiser.team]" always-expanded />
      </div>
    </tw-transition-container>
  </div>
</template>

<style scoped>

</style>
