<script>
import twButtonsMixin from '@/mixins/twButtonsMixin.js'

export default {
  name: 'TwButton',
  mixins: [twButtonsMixin]
}
</script>

<template>
  <component :is="shouldBeComponent" :to="to" :disabled="disabled || loading" type="button" :class="twClasses">
    <svg v-show="loading" :class="loadingClasses" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
      <path class="opacity-85" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
    <i v-if="!loading && icon && !iconRight" :class="['pi', 'pi-' + icon]" />
    <slot name="default" />
    <i v-if="!loading && icon && iconRight" :class="['pi', 'pi-' + icon]" />
  </component>
</template>

<style scoped>

</style>
