<script>
import { BarsArrowUpIcon, UsersIcon } from '@heroicons/vue/20/solid'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwAlert from '@/components/generic/tw-alert.vue'
export default {
  name: 'TwUrl',
  components: { TwAlert, TwValidationErrors, BarsArrowUpIcon, BarsArrowUpIcon},
  props: {
    modelValue: {
      type: [String,Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'my-fundraiser-slug'
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    },
    required: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: null
    },
    validateButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update','validateButtonClicked'],
  data() {
    return {
      Error: null,
    }
  },
}
</script>

<template>
  <div class="w-full mb-2">
    <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900"> {{ label }} </label>
    <div class="flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
      <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">{{ prepend }}</span>
      <input :id="id" :required="required" :value="modelValue" type="text" :name="id" :autocomplete="id" class="block flex-1 border-0 bg-transparent py-1.5 pl-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)">
      <button v-if="validateButton" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="$emit('validateButtonClicked')">
        <BarsArrowUpIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        <slot name="appendButton">
          Check
        </slot>
      </button>
    </div>
    <tw-validation-errors class="my-2" message-only :validation-result-error="Error" />
    <tw-alert ref="success" class="my-2" message="Fundraiser URL is available!" />
  </div>
</template>

<style scoped>

</style>
