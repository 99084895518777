<script>
export default {
  name: 'TwBadge',
  props: {
    variant: {
      type: String,
      required: false,
      default: 'gray'
    }
  },
  computed: {
    variantClasses() {
      return `bg-${this.variant}-100 text-${this.variant}-800 border-${this.variant}-400`;
    }
  }
}
</script>

<template>
  <span :class="'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium border-2 border-solid ' + variantClasses"><slot /></span>
</template>

<style scoped>

</style>
