<script>
import { useDonationsStore } from '@/stores/donation.js'
import TwLoadingSpinner from '@/components/generic/tw-loading-spinner.vue'
import { useDonationsPublicStore } from '@/stores/donationPublic.js'
import TwCard from '@/components/generic/tw-card.vue'
import TwButton from '@/components/generic/tw-button.vue'
export default {
  name: 'FundraiserSupporters',
  components: { TwButton, TwCard, TwLoadingSpinner },
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Loading: true
    }
  },
  computed: {
    donations() {
      if(this.fundraiser.parent_fundraiser_id) {
        return useDonationsPublicStore().itemsByFundraiserId(this.fundraiser.id)
      }
      return useDonationsPublicStore().itemsByParentFundraiserId(this.fundraiser.id)
    }
  },
  mounted() {
    this.fetchDonations()
  },
  methods: {
    async fetchDonations() {
      try {
        this.Loading = true
        if(this.fundraiser.parent_fundraiser_id) {
          await useDonationsPublicStore().fetchItems({
            fundraiser_id: this.fundraiser.id,
            not_paginated: true
          })
        }
        else {
          await useDonationsPublicStore().fetchItemsByFundraiserParentId(this.fundraiser.id)
        }
        this.Loading = false
      } catch (e) {
        console.error(e)
        this.Loading = false
      }
    }
  }
}
</script>

<template>
  <div>
    <h1 class="text-3xl text-center mt-4">
      Supporters
    </h1>
    <tw-loading-spinner v-if="Loading" />
    <div v-else-if="!Loading && donations.length > 0">
      <tw-card v-for="donation in donations" :key="'fundraiser-supporter-key-' + donation.id" size="sm" class="mt-2">
        <div class="grid grid-cols-4">
          <div class="col-span-3">
            <span class="font-bold"> {{ donation.cached.name }} </span>
          </div>
          <div class="text-right">
            <span> {{ donation.cached.amount_formatted }} </span>
          </div>
        </div>
        <div v-if="donation.comment" class="text-xs text-blue-900">
          {{ donation.comment }}
        </div>
        <div class="text-xs text-gray-500 mt-2">
          <span>Source: </span>
          <router-link class="text-blue-500 italic" :to="{ name: 'FundraiserPublicPage', params: { fundraiserId: donation.fundraiser_id, slug: donation.cached.fundraiser_slug } }">
            {{ donation.cached.fundraiser_title }}
          </router-link>
        </div>
      </tw-card>
    </div>
    <tw-card v-else class="text-center text-xl text-green-800">
      No supporters yet, make a donation to become the first supporter!
    </tw-card>
  </div>
</template>

<style scoped>

</style>
