<script>
import { useFundraiserPublicStore } from '@/stores/fundraiserPublic.js'

export default {
  name: 'FundraiserTopAdvocates',
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ValidationErrorMessage:null,
      Loading:false,
      TopAdvocates: []
    }
  },
}
</script>

<template>
  <div>
    <div class="text-xl uppercase text-center">
      Top Advocates for this campaign
    </div>
  </div>
</template>

<style scoped>

</style>
