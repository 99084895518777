<script>
import TwCard from '@/components/generic/tw-card.vue'
import TwInput from '@/components/generic/forms/tw-input.vue'
import TwEmail from '@/components/generic/forms/tw-email.vue'
import TwPassword from '@/components/generic/forms/tw-password.vue'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwButton from '@/components/generic/tw-button.vue'
import TwCheckbox from '@/components/generic/forms/tw-checkbox.vue'
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth.js'

export default {
  name: 'LoginForm',
  components: { TwCheckbox, TwButton, TwValidationErrors, TwPassword, TwEmail, TwInput, TwCard },

  props: {
    noRedirect: {
      type: Boolean,
      default:false
    }
  },
  emits: ['userLoggedIn'],
  data() {
    return {
      ValidationErrorMessage: null,
      Loading: false,
      LoginEmail: null,
      LoginPassword: null,
      LoginRemember:false,
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['attempt_user', 'set_user','login']),
    async loginSubmit() {
      try {
        this.ValidationErrorMessage = null
        await this.login({
          email: this.LoginEmail,
          password: this.LoginPassword,
          remember: this.LoginRemember
        })
        if(!this.noRedirect) {
          await this.$router.push({ name: 'DashboardPage' })
        }
        this.$emit('userLoggedIn')
      } catch (e) {
        if(e.response) {
          this.ValidationErrorMessage = e.response.data
        }
      }
    },
  },
}
</script>

<template>
  <form @submit.prevent="loginSubmit">
    <tw-email v-model="LoginEmail" label="Email:" />
    <tw-password v-model="LoginPassword" label="Password:" />
    <router-link :to="{ name: 'ForgotPassword' }" class="text-sm float-right text-gray-500 hover:text-gray-200">
      Forgot your password?
    </router-link>
    <tw-checkbox v-model="LoginRemember" label="Remember me" />
    <tw-validation-errors :validation-result-error="ValidationErrorMessage" />
    <div class="clear-both mt-2" />
    <div class="flex justify-center">
      <tw-button size="lg" @click="loginSubmit">
        Login
      </tw-button>
    </div>
  </form>
</template>

<style scoped>

</style>
