<script>
import fundraiserPublicFetchMixin from '@/mixins/fundraiserPublicFetchMixin.js'
import TwCard from '@/components/generic/tw-card.vue'
import TwButton from '@/components/generic/tw-button.vue'
import TwContainer from '@/components/generic/tw-container.vue'
import TwModal from '@/components/generic/modal/tw-modal.vue'
import RegisterUserAccount from '@/components/user/register-user-account.vue'
const Login = () => import('@/pages/auth/Login.vue')
import LoginForm from '@/components/auth/login-form.vue'
import loggedInUserMixin from '@/mixins/loggedInUserMixin.js'
import TwInput from '@/components/generic/forms/tw-input.vue'
import TwAmount from '@/components/generic/forms/tw-amount.vue'
import TwUrl from '@/components/generic/forms/tw-url.vue'
import TwWysiwyg from '@/components/generic/forms/tw-wysiwyg.vue'
import TwDate from '@/components/generic/forms/tw-date.vue'
import { useAuthStore } from '@/stores/auth.js'
import { useFundraiserStore } from '@/stores/fundraiser.js'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwUploadField from '@/components/generic/uploads/tw-upload-field.vue'
import TwSelect from '@/components/generic/forms/tw-select.vue'
import TeamEditModal from '@/components/teams/team-edit-modal.vue'

export default {
  name: 'FundraiserJoinPage',
  components: { TeamEditModal, TwSelect, TwUploadField, TwValidationErrors, TwDate, TwWysiwyg, TwUrl, TwAmount, TwInput, LoginForm, Login, RegisterUserAccount, TwModal, TwContainer, TwButton, TwCard },
  mixins:[fundraiserPublicFetchMixin, loggedInUserMixin],
  data() {
    return {
      LoadingJoiningCause: false,
      ValidationErrorMessage: null,
      FundraiserData: {
        title: null,
        short_description: null,
        long_description: null,
        goal: null,
        deadline: null
      },
      TeamCreated: null,
      YoutubeVideoUrl: null,
      ShowYoutubeVideoPreview: false,
      PhotoUploaded: null
    }
  },
  computed: {
    youtubeVideoSlug() {
      return this.YoutubeVideoUrl.split('v=')[1]
    },
    teamsAvailable() {
      const teams = this.fundraiser.teams.map(team => ({ id: team.id, name: team.name }))
      if(this.TeamCreated) {
        teams.push({ id: this.TeamCreated.id, name: this.TeamCreated.name })
      }
      return teams
    }
  },
  async mounted() {
    await this.fetchFundraiser()
    if(this.fundraiser.parent_fundraiser_id) {
      // only allow joining the parent fundraiser
      this.$router.push({ name: 'FundraiserPublicPage', params: { fundraiserId: this.fundraiser.parent_fundraiser_id, slug: this.fundraiser.parent_fundraiser_slug } })
    }
    this.FundraiserData.title = this.fundraiser.title
    this.FundraiserData.short_description = this.fundraiser.short_description
    this.FundraiserData.long_description = this.fundraiser.long_description
    this.FundraiserData.goal = this.fundraiser.goal
    this.FundraiserData.deadline = this.fundraiser.deadline

  },
  methods: {
    async userRegistered(user) {
      this.$refs.userRegisterAccountModal.close()
      await useAuthStore().login(user)
      this.FundraiserData.title = this.fundraiser.title + ' by ' + user.name
    },
    userLoggedIn() {
      this.$refs.userLoginModal.close()
      this.FundraiserData.title = this.fundraiser.title + ' by ' + useAuthStore().user.name
    },
    createdTeam(team) {
      this.TeamCreated = team
      this.FundraiserData.belongs_to_team_id = team.id
    },
    async joinCause() {
      try {
        if(confirm('Are you sure you want to join this cause?')) {
          this.ValidationErrorMessage = null
          this.LoadingJoiningCause = true
          const fundraiser = await useFundraiserStore().fundraiserJoin(this.fundraiser.id, {
            ...this.FundraiserData,
            ...(this.PhotoUploaded && { photo_upload_id: this.PhotoUploaded.id }),
            ...(this.YoutubeVideoUrl && { youtube_video_url: this.YoutubeVideoUrl })
          })
          this.LoadingJoiningCause = false
          this.$router.push({ name: 'FundraiserPublicPage', params: { fundraiserId: fundraiser.id, slug: fundraiser.slug } })
        }
      } catch (e) {
        console.error(e)
        this.LoadingJoiningCause = false
        if (e.response) this.ValidationErrorMessage = e.response.data
        throw e
      }
    }
  }
}
</script>

<template>
  <tw-container>
    <h1 v-if="fundraiser" class="text-3xl text-center my-4">
      Join this Cause! Create a personal fundraising page for <span class="italic">{{ fundraiser.title }}</span>
    </h1>
    <tw-card v-if="!Loading && fundraiser">
      <div v-if="fundraiser.settings && fundraiser.settings.signup_instructions">
        <div v-html="fundraiser.settings.signup_instructions" />
      </div>
      <tw-card v-if="loggedInUser" class="bg-yellow-100 rounded-2xl mt-4">
        <div class="flex justify-center text-3xl text-yellow-800">
          Welcome, <span class="ml-2 font-bold italic"> {{ loggedInUser.name }}</span>
        </div>
      </tw-card>
      <div v-else class="my-2">
        <span class="text-2xl"> Do you already have an account with us? </span>
        <div class="flex justify-center gap-4">
          <tw-button size="lg" @click="$refs.userRegisterAccountModal.open()">
            Signup
          </tw-button>
          <tw-button size="lg" @click="$refs.userLoginModal.open()">
            Login
          </tw-button>
        </div>
      </div>
      <div v-if="loggedInUser" class="mt-2">
        <tw-input v-model="FundraiserData.title" :disabled="fundraiser.settings && fundraiser.settings.lock_title" label="Title of your fundraiser" />
        <tw-input v-model="FundraiserData.short_description" :disabled="fundraiser.settings && fundraiser.settings.lock_short_desc" label="Short and catchy description" />
        <tw-amount v-model="FundraiserData.goal" :disabled="fundraiser.settings && fundraiser.settings.lock_goal" prepend="$" label="Enter your goal amount" />
        <tw-date v-model="FundraiserData.deadline" :disabled="fundraiser.settings && fundraiser.settings.lock_deadline" label="Fundraising Deadline" />
        <tw-wysiwyg v-model="FundraiserData.long_description" :disabled="fundraiser.settings && fundraiser.settings.lock_long_desc" />
        <tw-validation-errors :validation-result-error="ValidationErrorMessage" />
      </div>
      <div v-if="loggedInUser && fundraiser.settings.enable_teams">
        <div class="text-xl">
          Join a team <span v-if="fundraiser.settings.enable_create_teams"> or create a team </span>
        </div>
        <div class="flex flex-row gap-2">
          <tw-select v-model="FundraiserData.belongs_to_team_id" :options="teamsAvailable" value-key="id" label-key="name" />
          <tw-button v-if="fundraiser.settings.enable_create_teams" @click="$refs.createTeamModal.open()">
            Create a team
          </tw-button>
        </div>
        <team-edit-modal ref="createTeamModal" :fundraiser="fundraiser" @created="createdTeam" />
      </div>

      <div v-if="loggedInUser" class="mt-2 grid grid-cols-12">
        <div class="col-span-5">
          <div class="text-center">
            <div class="text-2xl mb-4">
              Upload a Photo or an Image
            </div>
            <tw-upload-field v-model="PhotoUploaded" />
          </div>
          <div v-if="PhotoUploaded" class="text-center p-4">
            <a :href="PhotoUploaded.url" target="_blank" class="text-blue-500 underline">
              <img :src="PhotoUploaded.url" class="max-h-fit border-1 border-gray-300 shadow-lg rounded-2xl object-cover object-center">
            </a>
            <tw-button variant="danger" size="sm" @click="PhotoUploaded = null">
              Remove Photo
            </tw-button>
          </div>
        </div>
        <div class="text-3xl col-span-2 flex justify-center align-middle mt-4">
          OR
        </div>
        <div class="col-span-5">
          <tw-input v-model="YoutubeVideoUrl" label="Video from Youtube" placeholder="Youtube URL" @blur="ShowYoutubeVideoPreview = true" />
          <div v-if="ShowYoutubeVideoPreview && youtubeVideoSlug">
            <iframe class="embed-responsive-item w-full h-80" :src="`https://www.youtube.com/embed/${youtubeVideoSlug}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
          </div>
        </div>
      </div>
      <div v-if="loggedInUser" class="flex justify-center">
        <tw-button size="lg" :loading="LoadingJoiningCause" @click="joinCause">
          Join this Cause
        </tw-button>
      </div>
      <tw-modal ref="userRegisterAccountModal" no-footer>
        <register-user-account @user-registered="userRegistered" />
      </tw-modal>
      <tw-modal ref="userLoginModal" no-footer>
        <login-form no-redirect @user-logged-in="userLoggedIn" />
      </tw-modal>
    </tw-card>
  </tw-container>
</template>

<style scoped>

</style>
