<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <slot name="default">
          Select
        </slot>
        <tw-badge v-if="modelValue" variant="blue">
          {{ label }}
        </tw-badge>
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute right-0 z-100 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-for="(option, index) in options" :key="'tw-key-option-' + index + '-' + id" v-slot="{ active }" @click="selectedOption(index)">
            <a :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
              {{ $filters.formatString(labelKey? option[labelKey]: option) }}
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import mixin from '@/mixins/twSelectFieldMixin.js'
import { defineProps, defineModel, computed } from 'vue'
import TwBadge from '@/components/generic/tw-badge.vue'

const props = defineProps(mixin.props)
const model = defineModel({
  type: [String, Number, Object],
})

const selectedOption = (option) => {
  if(option > -1) {
    model.value = props.valueKey ? props.options[option][props.valueKey] : props.options[option]
  }
  else {
    model.value = null
  }
}

const label = computed(() => {
  const selected = props.options.find(option => props.valueKey ? option[props.valueKey] === model.value : option === model.value)
  return selected ? (props.labelKey ? selected[props.labelKey] : selected) : null
})

</script>
