import { defineStore } from 'pinia'
import { actions, getters, state } from './ext/items.js'


export const useTeamsStore = defineStore('teams', {
  id: 'teams',
  state: () => ({
    items: [],
    api: '/api/v1/teams'
  }),
  getters: {
    ...getters,
    getItemsByFundraiserId: (state) => (fundraiserId) => state.items.filter(item => item.fundraiser_id === fundraiserId),
  },
  actions: {
    ...actions,
  }
})
