import { createRouter, createWebHistory } from "vue-router"
import { useAuthStore } from '@/stores/auth.js'

import routes from './routes.js'
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});



router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  if(to.meta.requiresAuth && !authStore.currentUser) {
    await authStore.attempt_user() // make at least an attempt to get the user
  }
  if (to.meta.requiresAuth && !authStore.currentUser) {
    next({ name: "Login" })
  } else if (to.meta.isGuest && authStore.currentUser) {
    next({ name: "Home" })
  } else {
    next();
  }
});

export default router;
