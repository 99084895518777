import { defineStore } from 'pinia'
import { actions, getters, state } from './ext/items.js'
import axios from 'axios'


export const useUsersStore = defineStore('users', {
  id: 'users',
  state: () => ({
    items: [],
    api: '/api/v1/users'
  }),
  getters: {
    ...getters
  },
  actions: {
    async registerUser(payload) {
      const { data } = await axios.post(this.api, payload)
      this.itemPatchState(data)
      return data
    },
    ...actions
  }
})
