const ContactPage = () => import('@/pages/static/ContactPage.vue')
const StaticForOrganizationsPage = () => import('@/pages/static/StaticForOrganizationsPage.vue')
const StaticFreeToUsePage = () => import('@/pages/static/StaticFreeToUsePage.vue')
const StaticHowItWorksPage = () => import('@/pages/static/StaticHowItWorksPage.vue')

export default [
  { path: "/contact", name: 'ContactPage', component: ContactPage },
  { path: "/for-organizations", name: 'StaticForOrganizationsPage', component: StaticForOrganizationsPage },
  { path: "/free-to-use", name: 'StaticFreeToUsePage', component: StaticFreeToUsePage },
  { path: "/how-it-works", name: 'StaticHowItWorksPage', component: StaticHowItWorksPage },

]
