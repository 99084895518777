<script>


import TwCard from '@/components/generic/tw-card.vue'

export default {
  name: 'FundraiserCard',
  components: { TwCard },
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<template>
  <tw-card class="flex flex-col justify-between">
    <div class="p-2">
      <router-link v-if="fundraiser.cached && fundraiser.cached.cover_image_url" :to="{ name: 'FundraiserPublicPage', params: { fundraiserId: fundraiser.id, slug: fundraiser.slug }}" class="block mb-4">
        <img class="rounded-t-lg" :src="fundraiser.cached.cover_image_url" alt="Fundraiser Banner">
      </router-link>

      <div v-if="fundraiser.cached && fundraiser.cached.percentage_raised" class="mb-4">
        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full" :style="'width: ' + (fundraiser.cached.percentage_raised <= 100? fundraiser.cached.percentage_raised: 100) + '%;'">
            {{ fundraiser.cached.percentage_raised }}%
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'FundraiserPublicPage', params: { fundraiserId: fundraiser.id, slug: fundraiser.slug }}">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {{ fundraiser.title }}
        </h5>
      </router-link>
      <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
        {{ fundraiser.short_description }}
      </p>
    </div>

    <div class="p-2">
      <router-link :to="{ name: 'FundraiserPublicPage', params: { fundraiserId: fundraiser.id, slug: fundraiser.slug }}" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 float-right">
        Read more
        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg>
      </router-link>
      <div class="clear-both mb-2"></div>
      <div class="grid grid-cols-2 align-bottom text-xs">
        <div v-if="fundraiser.cached"> {{ fundraiser.cached.percentage_raised }}% funded.  </div>
        <div class="text-right">
          {{ $filters.timeLeft(fundraiser.deadline) }}
        </div>
      </div>
    </div>
  </tw-card>
</template>

<style scoped>

</style>
