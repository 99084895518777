export default {
  data() {
    return {
      Loading: false,
      Error: null,
    };
  },
  methods: {
    async fetchData(method, params) {
      this.loading = true;
      try {
        this.Loading = true
        await method(params)
        this.Loading = false
      } catch (e) {
        console.error(e)
        this.Loading = false
      }
    },
  },
}
