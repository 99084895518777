const Authenticated = () => import('@/layouts/Authenticated.vue')
const Home = () => import('@/pages/HomePage.vue')
const User = () => import('@/pages/User.vue')
const ConfirmPassword = () => import('@/pages/auth/ConfirmPassword.vue')
const Guest = () => import('@/layouts/Guest.vue')
const Register = () => import('@/pages/auth/Register.vue')
const Login = () => import('@/pages/auth/Login.vue')
const VerifyEmail = () => import('@/pages/auth/VerifyEmail.vue')
const TwoFactorChallenge = () => import('@/pages/auth/TwoFactorChallenge.vue')
const ForgotPassword = () => import('@/pages/auth/ForgotPassword.vue')
const ResetPassword = () => import('@/pages/auth/ResetPassword.vue')
const NotFound = () => import('@/pages/NotFound.vue')
const AccessDenied = () => import('@/pages/AccessDenied.vue')
const PublicPages = () => import('@/layouts/PublicPages.vue')
// route packs
import routesDashboard from '@/router/routes/routesDashboard.js'
import routesFundraisers from '@/router/routes/routesFundraisers.js'
import routesOrganizations from '@/router/routes/routesOrganizations.js'
import routesTeams from '@/router/routes/routesTeams.js'
import routesDonations from '@/router/routes/routesDonations.js'
import routesStatic from '@/router/routes/routesStatic.js'
import routesFundraisersPublic from '@/router/routes/routesFundraisersPublic.js'

export default [
  {
    path: '/',
    component: PublicPages,
    children: [
      { path: "", name: 'Home', component: Home },
      ...routesStatic,
      ...routesFundraisersPublic
    ]
  },
  {
    name: 'AccessDenied',
    path:'/access-denied',
    component: AccessDenied
  },
  {
    path: '/dashboard',
    component: Authenticated,
    meta: { requiresAuth: true },
    children: [
      { path: "", name: 'DashboardPage', component: Home },
      { path: "user", name: 'User', component: User },
      { path: "confirm-password", name: 'ConfirmPassword', component: ConfirmPassword },
      ...routesDashboard,
      ...routesFundraisers,
      ...routesOrganizations,
      ...routesTeams,
      ...routesDonations,
    ]
  },
  {
    path: '/auth',
    redirect: "/login",
    component: Guest,
    meta: { isGuest: true },
    children: [
      { path: "/register", name: 'Register', component: Register },
      { path: "/login", name: 'Login', component: Login },
      { path: "/verify-email", name: 'VerifyEmail', component: VerifyEmail },
      { path: "/two-factor-challenge", name: 'TwoFactorChallenge', component: TwoFactorChallenge },
      { path: "/forgot-password", name: 'ForgotPassword', component: ForgotPassword },
      { path: "/reset-password/:token", name: 'ResetPassword', component: ResetPassword }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
  }
]
