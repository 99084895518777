<script>
import modalCascadeMixin from '@/mixins/modalCascadeMixin.js'
import TwModal from '@/components/generic/modal/tw-modal.vue'
import TwInput from '@/components/generic/forms/tw-input.vue'
import { useFundraiserPublicStore } from '@/stores/fundraiserPublic.js'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwEmail from '@/components/generic/forms/tw-email.vue'
import TwNotification from '@/components/generic/tw-notification.vue'

export default {
  name: "FundraiserSubscribeToUpdatesModal",
  components: { TwNotification, TwEmail, TwValidationErrors, TwInput, TwModal },
  mixins:[modalCascadeMixin],
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Loading: false,
      ValidationErrorMessage: null,
      Name: null,
      Email: null
    }
  },
  computed: {
    validEmail() {
      return this.Email && this.Email.includes('@') && this.Email.includes('.')
    }
  },
  methods: {
    async subscribeToFundraiser() {
      try {
        this.ValidationErrorMessage = null
        this.Loading = true
        await useFundraiserPublicStore().subscribeToFundraiser(this.fundraiser.id, {
          name: this.Name,
          email: this.Email
        })
        this.Loading = false
        this.close()
        this.$refs.notification.show()
      } catch (e) {
        console.error(e)
        this.Loading = false
        if (e.response) this.ValidationErrorMessage = e.response.data
        throw e
      }
    }
  }
}
</script>

<template>
  <tw-modal ref="modal" :ok-disabled="!validEmail" :loading="Loading" ok-text="Subscribe to fundraiser updates" :title="'Subscribe to receive updates for ' + fundraiser.title" @ok="subscribeToFundraiser">
    <div class="grid grid-cols-2 gap-4">
      <div> <tw-input v-model="Name" label="Name:" /> </div>
      <div> <tw-email v-model="Email" label="Email:" /> </div>
    </div>
    <tw-validation-errors :validation-result-error="ValidationErrorMessage" />
  </tw-modal>
  <tw-notification ref="notification">
    <p>Thank you for subscribing to updates for this fundraiser. You will receive an email when there are updates.</p>
  </tw-notification>
</template>

<style scoped>

</style>
