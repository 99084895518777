/*
 *  Copyright (C) UMISIS SOLUTIONS SRL - All Rights Reserved
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by Emanuel Gug <manu144x@gmail.com>, September 2018
 *
 */

import { extendMoment } from 'moment-range'
import humanizeString from 'humanize-string'

import Moment from 'moment'
const moment = extendMoment(Moment)
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(moment)

const timeZonesDictionary = {
  30: 'US/Hawaii',
  40: 'US/Alaska',
  50: 'US/Pacific',
  60: 'US/Arizona',
  64: 'US/Mountain',
  72: 'US/Central',
  82: 'US/Eastern'
}

export default [
  {
    name: 'formatDate',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('M/D/YYYY, h:mm a')
      }
    }
  },
  {
    name: 'formatDateFromNow',
    execute: (value) => {
      if (value) {
        return moment(String(value)).fromNow()
      }
    }
  },
  {
    name: 'formatDateAndTime',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('ddd MMM D, YYYY h:mm a')
      }
    }
  },
  {
    name: 'formatDateTimeOnly',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('h:mm a')
      }
    }
  },
  {
    name: 'formatTime',
    execute: (value) => {
      if (value) {
        return moment(String(value), 'HH:mm').format('h:mm a')
      }
    }
  },
  {
    name: 'formatDollars',
    execute: (value) => {
      if (value) {
        return '$ ' + (value).toFixed(2)
      } else return '$ 0.00'
    }
  },
  {
    name: 'formatMonthName',
    execute: (value) => {
      if (value) {
        return moment(String(value), 'MM').format('MMMM')
      }
    }
  },
  {
    name: 'formatString',
    execute: (value) => {
      if (value) {
        return humanizeString(value)
      }
    }
  },
  {
    name: 'timeLeft',
    execute: (value, noSuffix) => {
      if (value) {
        const date = moment(value)
        return date.fromNow(noSuffix)
      }
    }
  },
  {
    name: 'interval',
    execute: (value) => {
      if (value) {
        return moment.duration(value * 1000).format('h:mm:ss')
      }
    }
  },
  {
    name: 'processHalfHoursIdentifier',
    execute: (value) => {
      if (value) {
        if (value % 2 === 0) {
          return (value / 2) + ':00'
        } else {
          return Math.floor(value / 2) + ':30'
        }
      }
    }
  },
  {
    name: 'formatDuration',
    execute: (value) => {
      if (value) {
        if (value % 1440 === 0) {
          let str = value * 1 / 1440
          str += ' days'
          return str
        }
        if (value % 60 === 0) {
          let str = value * 1 / 60
          str += ' hours'
          return str
        }

        switch (value) {
        case 60: {
          return ' 1 hour'
        }
        case 1440: {
          return '1 day'
        }
        case 10080: {
          return '1 week'
        }
        default: {
          return moment.duration(value * 60000).format('h:mm:ss')
        }
        }
      }
    }
  },
  {
    name: 'formatDateOnly',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('ddd MMM D, YYYY')
      }
    }
  },
  {
    name: 'formatDateEndOfShiftReport',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('M/D')
      }
    }
  },
  {
    name: 'limitString',
    execute: (value) => {
      if (value) {
        return value.substr(0, 35)
      }
    }
  },
  {
    name: 'capitalize',
    execute: (value) => {
      if (!value) {
        return ''
      }
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  {
    name: 'convertTimezone',
    execute: (value) => {
      if (!value) {
        return ''
      }
      if (timeZonesDictionary[value]) {
        return timeZonesDictionary[value]
      } else {
        return value
      }
    }
  },
  {
    name: 'localTime',
    execute: (value) => {
      if (!value) {
        return ''
      }
      if (timeZonesDictionary[value]) {
        return moment.tz(timeZonesDictionary[value]).format('h:mm a')
      } else {
        return value
      }
    }
  },
  {
    name: 'formatDurationFromSeconds',
    execute: (value) => {
      if (value) {
        const hhmmss = new Date(value * 1000).toISOString().substr(11, 8)
        return (hhmmss.indexOf('00:') === 0) ? hhmmss.substr(3) : hhmmss
      }
    }
  },
  {
    name: 'convertInfusionsoftUserId',
    execute: (value) => {
      const dictionary = {
        84600: 'Briela Malcolm',
        80680: 'Jessica McEwen',
        80678: 'Kimber Moore',
        14: 'Supervisor Dept',
        12: 'Amanda Davis',
        10: 'Service Dept',
        4: 'Jesse Merickel',
        2: 'Cheryl Hunt',
        1: 'IT Department'
      }
      if (dictionary[value]) {
        return dictionary[value]
      } else {
        return value
      }
    }
  },
  {
    name: 'formatDateSimple',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('M/D/YYYY')
      }
    }
  },
  {
    name: 'stringToUpperCase',
    execute: (value) => {
      if (value && typeof value === 'string') {
        return value.toUpperCase()
      }
    }
  },
  {
    name: 'limitEmployeeNameForAttendance',
    execute: (value) => {
      if (value) {
        let newValue = value.substr(0, 16)
        if (value.length > 16) {
          newValue += '...'
        }
        return newValue
      }
    }
  },
  {
    name: 'formatDurationMinutesAndSeconds',
    execute: (value) => {
      if (value) {
        return moment.duration(value * 1000).format('mm:ss')
      }
    }
  },
  {
    name: 'formatTimeOnly',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('h:mm A')
      }
    }
  },
  {
    name: 'formatDateWeekly',
    execute: (value) => {
      if (value) {
        return moment(String(value)).format('M/D/YYYY')
      }
    }
  },
  {
    name: 'formatRoundingTwoDecimals',
    execute: (value) => {
      if (value) {
        const dec = String(value).split('.')[1]
        const len = dec && dec.length > 2 ? dec.length : 2
        return Number(value).toFixed(len)
      } else {
        return value
      }
    }
  },
  {
    name: 'decisecondsToHm',
    execute: (value) => {
      value = parseInt(value)
      if (value > 0) {
        return moment.duration(value / 10, 'seconds').format('mm:ss', { trim: false })
      }
      return '00:00'
    }
  },
  {
    name: 'secondsToHm',
    execute: (value) => {
      if (value) {
        const isNegative = value < 0
        let positiveSec = 0
        if (isNegative) positiveSec = value * (-1)
        else positiveSec = value
        const SECONDS_PER_DAY = 86400
        const HOURS_PER_DAY = 24
        const days = Math.floor(positiveSec / SECONDS_PER_DAY)
        const remainderSeconds = positiveSec % SECONDS_PER_DAY
        const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 16)
        if (isNegative) return '-' + hms.replace(/^(\d+)/, h => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0'))
        else return hms.replace(/^(\d+)/, h => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0'))
      } else {
        return '00:00'
      }
    }
  },
  {
    name: 'formatCurrency',
    execute: (value) => {
      if (value) {
        return '$ ' + new Intl.NumberFormat('en-US').format(value)
      } else {
        return '$ 0.00'
      }
    }
  },
  {
    name: 'formatNumber',
    execute: (value) => {
      if (value) {
        return new Intl.NumberFormat('en-US').format(value)
      } else {
        return '0'
      }
    }
  },
  {
    name: 'formatPercentage',
    execute: (value) => {
      if (value) {
        return value.toFixed(2) + '%'
      } else {
        return '0%'
      }
    }
  },
  {
    name: 'formatMonthYear',
    execute: (value) => {
      if (value) {
        return moment(value.substring(1, 2), 'MM').format('MMMM') + ' ' + value.substring(3)
      } else {
        return null
      }
    }
  },
  {
    name: 'formatMonthYearFromYearMonth',
    execute: (value) => {
      if (value) {
        const [year, month] = value.split('-')
        const monthWithoutLeadingZero = parseInt(month, 10)
        return `${monthWithoutLeadingZero}/${year}`
      } else {
        return null
      }
    }
  },
  {
    name: 'formatPhoneNumber',
    execute: (value) => {
      if (!value) return ''

      const phoneNumber = value.replace(/\D/g, '')
      const phoneNumberLength = phoneNumber.length

      if (phoneNumberLength < 4) return phoneNumber

      if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
      }
      if (phoneNumberLength < 11) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
      }
      return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(1, 4)}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`
    }
  }
]
