<template>
  <component :is="layout">
    <router-view :key="$route.path" v-model:layout="layout" />
  </component>
  <tw-notification-container />
</template>

<script>

import TwNotificationContainer from '@/components/generic/tw-notification-container.vue'
import { useAuthStore } from '@/stores/auth.js'

export default {
  name: 'App',
  components: { TwNotificationContainer },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default'
    }
  },
  mounted() {
    if(!useAuthStore().user) {
      useAuthStore().attempt_user()
    }
  }
}
</script>
