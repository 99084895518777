const FundraisersIndexPage = () => import('@/pages/fundraisers/FundraisersIndexPage.vue')
const FundraiserEditPage = () => import('@/pages/fundraisers/FundraiserEditPage.vue')
const FundraiserDetailsPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserDetailsPage.vue')
const FundraiserCommunicationPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserCommunicationPage.vue')
const FundraiserImagesVideosPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserImagesVideosPage.vue')
const FundraiserTeamsPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserTeamsPage.vue')
const DonationsIndexPage = () => import('@/pages/donations/DonationsIndexPage.vue')
const FundraiserSupportersPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserSupportersPage.vue')
const FundraiserAdvancedSettingsPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserAdvancedSettingsPage.vue')
const FundraiserUpdatesPage = () => import('@/pages/fundraisers/fundraiser-edit/FundraiserUpdatesPage.vue')

export default [
  { path: "/fundraisers", name: 'FundraisersIndexPage', component: FundraisersIndexPage },
  { path: "/fundraisers/create", name: 'FundraiserCreatePage', component: FundraiserEditPage },
  {
    path: "/fundraisers/:fundraiserId",
    name: 'FundraiserEditPage',
    component: FundraiserEditPage,
    props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }),
    children: [
      { path:'', name:'FundraiserDetailsPage', component: FundraiserDetailsPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'donations', name:'FundraiserDonationsPage', component: DonationsIndexPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'communications', name:'FundraiserCommunicationPage', component: FundraiserCommunicationPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'images-videos', name:'FundraiserImagesVideosPage', component: FundraiserImagesVideosPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'teams', name:'FundraiserTeamsPage', component: FundraiserTeamsPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'supporters', name:'FundraiserSupportersPage', component: FundraiserSupportersPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'advanced-settings', name:'FundraiserAdvancedSettingsPage', component: FundraiserAdvancedSettingsPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
      { path:'updates', name:'FundraiserUpdatesPage', component: FundraiserUpdatesPage, props: route => ({ fundraiserId: parseInt(route.params.fundraiserId) }) },
    ],
  },
]
