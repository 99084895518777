<script>
import { ClockIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'FundraiserTimeLeft',
  components: {ClockIcon},
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="text-xl pt-1">
    <clock-icon class="h-8 inline" /> <span class="uppercase">Time Left:  {{ $filters.timeLeft(fundraiser.deadline, true) }} </span>
  </div>
</template>

<style scoped>

</style>
