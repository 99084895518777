<script>
import { ref } from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'TwNotificationContainer',
  components: { CheckCircleIcon, XMarkIcon },
  props: {
    timeout: {
      type: Number,
      default: 3
    }
  },
  emits: ['closed','shown'],
  data() {
    return {
      Show: true
    }
  },
  watch: {
    Show() {
      if(this.Show) {
        setTimeout(() => {
          this.Show = false
        }, 3*1000)
      }
    }
  },
  methods: {
    close() {
      this.Show = false
      this.$emit('closed')
    },
    show() {
      this.Show = true
      this.$emit('shown')
    }
  }
}
</script>

<template>
  <!-- NOT FINISHED -->
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div id="tw-notification-container" class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
    </div>
  </div>
</template>

<style scoped>

</style>
