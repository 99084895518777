<script>
import { ref } from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'TwNotification',
  components: { CheckCircleIcon, XMarkIcon },
  props: {
    timeout: {
      type: Number,
      default: 3
    },
    variant: {
      type: String,
      default: 'info'
    }
  },
  emits: ['closed','shown'],
  data() {
    return {
      Show: false
    }
  },
  watch: {
    Show() {
      if(this.Show) {
        setTimeout(() => {
          this.Show = false
        }, 3*1000)
      }
    }
  },
  methods: {
    close() {
      this.Show = false
      this.$emit('closed')
    },
    show() {
      this.Show = true
      this.$emit('shown')
    }
  }
}
</script>

<template>
  <teleport to="#tw-notification-container">
    <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="Show" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <div class="text-sm font-medium text-gray-900">
                <slot />
              </div>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="close">
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>

</style>
