<script>
import twButtonsMixin from '@/mixins/twButtonsMixin.js'
import twUploadFieldMixin from '@/mixins/twUploadFieldMixin.js'
import { ArrowUpTrayIcon } from '@heroicons/vue/16/solid'
import TwBadge from '@/components/generic/tw-badge.vue'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'

export default {
  name: "TwUploadField",
  components: { TwValidationErrors,  ArrowUpTrayIcon },
  mixins:[twButtonsMixin, twUploadFieldMixin],
  computed: {
    twIconClasses() {
      return [
        {'h-4' : this.size === 'default'},
        {'h-3' : this.size === 'sm'},
        {'h-4' : this.size === 'lg'},
        {'h-5' : this.size === 'xl'},
      ]
    },
    twClasses() {
      return [
        {'min-w-48': this.LoadingUploading},
        'btn',
        'transition ease-in-out duration-500',
        'btn-variant-' + this.variant,
        'btn-size-' + this.size,
        {'btn-pill': this.pill},
        {'btn-block': this.block}
      ];
    },
    loadingClasses() {
      return [
        'animate-spin',
        {'h-4 w-4' : this.size === 'default'},
        {'h-3 w-3' : this.size === 'sm'},
        {'h-6 w-6' : this.size === 'lg'},
        {'h-7 w-7' : this.size === 'xl'},
        'transition-all',
        'ease-in-out',
        'duration-500',
        {'text-black': ['secondary'].indexOf(this.variant) > -1},
        {'text-blue': ['primary'].indexOf(this.variant) > -1},
        {'text-white': ['warning','danger','info'].indexOf(this.variant) > -1}
      ];
    }
  }
}
</script>

<template>
  <span>
    <button :disabled="disabled || LoadingUploading" type="button" :class="twClasses" @click="$refs.uploadableField.click()">
      <svg v-show="LoadingUploading" :class="loadingClasses" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
        <path class="opacity-85" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
      <span v-if="LoadingUploading"> {{ UploadPercentage }}% </span>
      <slot>
        <arrow-up-tray-icon v-if="!LoadingUploading" :class="twIconClasses" />
        <span v-if="multiple"> Upload files </span>
        <span v-else> Upload file </span>
      </slot>
      <input id="uploadableField" ref="uploadableField" :accept="accept" :multiple="multiple" class="hidden" type="file" name="uploadableField" @change="syncFile()">
    </button>
    <tw-validation-errors :validation-result-error="UploadError" message-only />
  </span>
</template>

<style scoped>

</style>
