import FundraiserDonationReturnPage from '@/pages/fundraisers/public/FundraiserDonationReturnPage.vue'
import FundraiserJoinPage from '@/pages/fundraisers/public/FundraiserJoinPage.vue'
import FundraiserPublicPage from '@/pages/fundraisers/public/FundraiserPublicPage.vue'

export default [
  {
    path: "/fundraisers/:fundraiserId/:slug",
    name: 'FundraiserPublicPage',
    component: FundraiserPublicPage,
    props: route => ({ fundraiserId: parseInt(route.params.fundraiserId), slug: route.params.slug })
  },
  {
    path: "/fundraisers/:fundraiserId/join",
    name: 'FundraiserPublicJoinPage',
    component: FundraiserJoinPage,
    props: route => ({ fundraiserId: parseInt(route.params.fundraiserId)})
  },
  {
    path: "/fundraisers/:fundraiserId/donations/:donationId/return",
    name: 'FundraiserDonationReturnPage',
    component: FundraiserDonationReturnPage,
    props: route => ({ fundraiserId: parseInt(route.params.fundraiserId), donationId: parseInt(route.params.donationId) })
  }
]
