<script>
import TwListGroup from '@/components/generic/list-group/tw-list-group.vue'
import FundraiserTeamListGroupItem from '@/components/fundraisers/fundraiser-tabs/fundraiser-team-list-group-item.vue'

export default {
  name: 'FundraiserTeams',
  components: { FundraiserTeamListGroupItem, TwListGroup },
  props: {
    fundraiser: {
      type: Object,
      required: true
    },
    teams: {
      type: Array,
      required: false,
      default: () => []
    },
    alwaysExpanded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <tw-list-group>
    <fundraiser-team-list-group-item v-for="team in teams" :key="'fundraiser-teams-' + fundraiser.id + '-' + team.id" :always-expanded="alwaysExpanded" :team="team" />
  </tw-list-group>
</template>

<style scoped>

</style>
