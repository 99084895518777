<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import TwLoadingSpinner from '@/components/generic/tw-loading-spinner.vue'
import FundraiserCard from '@/components/fundraisers/fundraiser-card.vue'
import TwButton from '@/components/generic/tw-button.vue'
import { useFundraiserPublicStore } from '@/stores/fundraiserPublic.js'
import TwGrid from '@/components/generic/tw-grid.vue'

export default {
  name: 'FundraiserChildrenFundraisers',
  components: { TwGrid, TwButton, FundraiserCard, TwLoadingSpinner },
  mixins:[fetchDataMixin],
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      FilterType: null
    }
  },
  computed: {
    filterTypes() {
      return ['all_pages','top_pages','team_pages','sort_alphabetically']
    },
    fundraiserChildrenAllPages() {
      return useFundraiserPublicStore().getItemsByParentId(this.fundraiser.id)
    },
    fundraiserChildrenTopPages() {
      // this need to be sorted by the value raised
      return useFundraiserPublicStore().getItemsByParentId(this.fundraiser.id).sort((a, b) => {
        if(a.cached && b.cached) {
          return b.cached.raised - a.cached.raised
        }
        else {
          return 0
        }
      })
    },
    fundraiserChildrenTeamPages() {
      return useFundraiserPublicStore().getItemsByParentId(this.fundraiser.id).filter(fundraiser => {
        return fundraiser.team_id !== null
      })
    },
    fundraiserChildrenSortAlphabetically() {
      return useFundraiserPublicStore().getItemsByParentId(this.fundraiser.id).sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
    },
    fundraiserChildren() {
      if(this.FilterType) {
        switch(this.FilterType) {
        case 'all_pages':
          return this.fundraiserChildrenAllPages
        case 'top_pages':
          return this.fundraiserChildrenTopPages
        case 'team_pages':
          return this.fundraiserChildrenTeamPages
        case 'sort_alphabetically':
          return this.fundraiserChildrenSortAlphabetically
        }
      }
      return useFundraiserPublicStore().getItemsByParentId(this.fundraiser.id)
    }
  },
  mounted() {
    this.fetchFundraiserChildren()
  },
  methods: {
    computedPropName(what) {
      const name = 'fundraiserChildren' + what.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')
      return this[name].length
    },
    async fetchFundraiserChildren() {
      await this.fetchData(useFundraiserPublicStore().fetchItems, {
        parent_fundraiser_id: this.fundraiser.id,
        not_paginated: 1
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="text-center text-3xl">
      Supporter Pages
    </div>
    <tw-loading-spinner v-if="Loading" />
    <div v-else>
      <div class="grid grid-cols-2 md:flex rounded-md shadow-sm justify-center gap-1">
        <tw-button v-for="filter in filterTypes" :key="'fundraiser-children-filter-types-key-' + filter" :variant="FilterType === filter ? 'primary':'light'" aria-current="page" class="" @click="FilterType = filter">
          {{ $filters.formatString(filter) }} <span class="bg-blue-100 text-blue-800 text-xs font-medium me-1 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"> {{ computedPropName(filter) }} </span>
        </tw-button>
      </div>
      <tw-grid class="mt-4">
        <fundraiser-card v-for="childFundraiser in fundraiserChildren" :key="childFundraiser.id" :fundraiser="childFundraiser" />
      </tw-grid>
    </div>
  </div>
</template>

<style scoped>

</style>
