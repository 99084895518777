<script>
import TwTransitionContainer from '@/components/generic/tw-transition-container.vue'

export default {
  name: 'TwValidationErrors',
  components: { TwTransitionContainer },
  props: {
    validationResultError: {
      type: Object,
      default: null
    },
    messageOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closed','close'],
  data() {
    return {
      Show: true
    }
  },
  watch: {
    validationResultError() {
      this.Show = true
    }
  },
  methods: {
    closeIt() {
      this.$emit('closed')
      this.$emit('close')
      this.Show = false
    }
  }
}
</script>

<template>
  <tw-transition-container>
    <div v-if="validationResultError && Show" class="rounded-md bg-red-50 p-4 mt-2">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            {{ validationResultError.message }}
          </h3>
          <div v-if="!messageOnly && validationResultError.errors" class="mt-2 text-sm text-red-700">
            <ul role="list" class="list-disc space-y-1 pl-5">
              <li v-for="(key, index) in Object.keys(validationResultError.errors)" :key="'error-' + index">
                <span class="font-semibold">{{ $filters.formatString(key) }}:</span> <span v-for="(error, errorIndex) in validationResultError.errors[key]" :key="'error-field-' + errorIndex">{{ error }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button type="button" class="inline-flex rounded-md bg-red-50 p-1.5 text-red-400 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50" @click="closeIt">
              <span class="sr-only">Dismiss</span>
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </tw-transition-container>
</template>

<style scoped>

</style>
