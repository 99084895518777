import { defineStore } from 'pinia'
import { actions, getters, state } from './ext/items.js'
import axios from 'axios'


export const useDonationsPublicStore = defineStore('donationsPublic', {
  id: 'donationsPublic',
  state: () => ({
    items: [],
    api: '/api/v1/donations-public'
  }),
  getters: {
    itemsByFundraiserId: (state) => (fundraiserId) => {
      return state.items.filter(item => item.fundraiser_id === fundraiserId)
    },
    itemsByParentFundraiserId: (state) => (fundraiserId) => {
      return state.items.filter(item => item.parent_fundraiser_id === fundraiserId)
    },
    ...getters
  },
  actions: {
    async fetchItemsByFundraiserParentId(fundraiserId) {
      const { data } = await axios.get(`${this.api}`, {
        params: {
          parent_fundraiser_id: fundraiserId,
          not_paginated: true
        }
      })
      this.items = data.data
      return data
    },
    ...actions
  }
})
