<script>
import twSelectFieldMixin from '@/mixins/twSelectFieldMixin.js'

export default {
  name: 'TwSelect',
  mixins: [twSelectFieldMixin],
}
</script>

<template>
  <fieldset class="">
    <label v-if="label"> {{ label }} </label>
    <select :id="id" v-model="SelectedIndex" :required="required" :name="id" :autocomplete="id" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      <option :value="null" disabled selected>
        {{ placeholder }}
      </option>
      <slot>
        <option v-for="(option, index) in options" :key="'tw-key-option-' + index + '-' + id" :value="index">
          {{ labelKey? option[labelKey]: option }}
        </option>
      </slot>
    </select>
  </fieldset>
</template>

<style scoped>

</style>
