<script>
export default {
  name: 'TwPassword',
  props: {
    modelValue: {
      type: [String,Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update'],
}
</script>

<template>
  <div class="w-full mb-2">
    <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900 mb-1"> {{ label }} </label>
    <input :id="id" :required="required" :value="modelValue" type="password" :name="id" :autocomplete="id" class="w-full block rounded-lg border dark:border-none dark:bg-neutral-600 py-[9px] px-3 pr-4 text-sm focus:border-gray-400 focus:ring-1 focus:ring-blue-400 focus:outline-none" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)">
  </div>
</template>

<style scoped>

</style>
