<script>
import { Duration, DateTime } from 'luxon'
import { HeartIcon, CurrencyDollarIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'

import FundraiserSupporters from '@/components/fundraisers/fundraiser-supporters.vue'
import FundraiserDonate from '@/components/fundraisers/fundraiser-donate.vue'
import FundraiserTimeLeft from '@/components/fundraisers/fundraiser-time-left.vue'
import TwCard from '@/components/generic/tw-card.vue'
import FundraiserTopAdvocates from '@/components/fundraisers/fundraiser-top-advocates.vue'
import TwButton from '@/components/generic/tw-button.vue'
import FundraiserSubscribeToUpdatesModal from '@/components/fundraisers/fundraiser-subscribe-to-updates-modal.vue'
import FundraiserSocialsShare from '@/components/fundraisers/fundraiser-socials-share.vue'
export default {
  name: 'FundraiserRightColumn',
  components: { FundraiserSocialsShare, FundraiserSubscribeToUpdatesModal, TwButton, FundraiserTopAdvocates, TwCard, FundraiserTimeLeft, FundraiserDonate, FundraiserSupporters, HeartIcon, CurrencyDollarIcon, EnvelopeIcon },
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  computed: {
    fundraiserDeadlineDays () {
      return Math.round(DateTime.fromISO(this.fundraiser.deadline).diffNow('days').days)
    }
  }
}
</script>

<template>
  <div>
    <div v-if="fundraiser.logo" class="mb-2 flex justify-center">
      <img :src="fundraiser.logo.url" class="max-h-32" :alt="'Logo for ' + fundraiser.title">
    </div>
    <div v-if="fundraiser.cached" class="mb-2">
      <div class="bg-cyan-600 p-5 text-center text-white rounded-2xl">
        <div class="text-4xl">
          ${{ fundraiser.cached.raised_formatted }}
        </div>
        <div class="text-sm uppercase mt-2 mb-10">
          Raised of <span class="font-bold">${{ fundraiser.goal_formatted }}</span> goal.
        </div>
        <div class="grid grid-cols-3">
          <div class="text-center">
            <div class="text-6xl">
              {{ fundraiser.cached.donations_count }}
            </div>
          </div>
          <div class="text-center">
            <div class="rounded-full p-8 bg-white text-blue-400 border-2">
              <div class="text-4xl">
                {{ fundraiser.cached.percentage_raised }}%
              </div>
              <div class="uppercase">
                of goal
              </div>
            </div>
          </div>
          <div>
            <div class="text-xl">
              <div v-if="fundraiserDeadlineDays < 0" class="m-4">
                Ended {{ $filters.timeLeft(fundraiser.deadline) }}
              </div>
              <div v-else class="text-center">
                <div class="text-6xl">
                  {{ fundraiserDeadlineDays }}
                </div>
                <div class="text-center">
                  days left
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <tw-card>
      <fundraiser-time-left class="mb-5" :fundraiser="fundraiser" />
      <div class="uppercase ml-10">
        <heart-icon class="h-4 inline" /> Organizer: {{ fundraiser.cached.organizer_name }}
      </div>
      <div class="uppercase ml-10">
        <currency-dollar-icon class="h-4 inline" /> Beneficiary: {{ fundraiser.cached.beneficiary_name }}
      </div>
    </tw-card>
    <tw-card v-if="fundraiser.settings && fundraiser.settings.enable_supporters && !fundraiser.parent_fundraiser_id">
      <tw-button :to="{name: 'FundraiserPublicJoinPage', params: { fundraiserId: fundraiser.id } }" size="lg" block>
        Join this Cause
      </tw-button>
    </tw-card>
    <tw-card>
      <div class="text-xl text-center uppercase mb-4">
        Share this cause on social media
      </div>
      <fundraiser-socials-share :fundraiser="fundraiser" />
    </tw-card>
    <tw-card v-if="fundraiser.cached && fundraiser.cached.top_advocates && fundraiser.cached.top_advocates.length > 0" class="mt-2">
      <fundraiser-top-advocates :fundraiser="fundraiser" />
    </tw-card>
    <tw-button size="lg" variant="secondary" block class="uppercase" @click="$refs.subscribeToFundraiserModal.open()">
      <envelope-icon class="h-6" />  Subscribe to updates
    </tw-button>
    <fundraiser-subscribe-to-updates-modal ref="subscribeToFundraiserModal" :fundraiser="fundraiser" />
  </div>
</template>

<style scoped>

</style>
