<script>
export default {
  name: 'FundraiserSocialsShare',
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sharing: {
        url: this.fundraiser.url,
        title: this.fundraiser.title,
        description: this.fundraiser.short_description,
        hashtags: 'fundraiser,grouprev',
        twitterUser: 'grouprev'
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
        { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
      ]
    }
  }
}
</script>

<template>
  <share-network
    v-for="network in networks"
    :key="network.network"
    class="btn btn-block mb-2 text-white py-4 text-md"
    :network="network.network"
    :style="{backgroundColor: network.color}"
    :url="sharing.url"
    :title="sharing.title"
    :description="sharing.description"
    :quote="sharing.quote"
    :hashtags="sharing.hashtags"
    :twitter-user="sharing.twitterUser"
  >
    <icon size="xl" :icon="network.icon" />
    <span>{{ network.name }}</span>
  </share-network>
</template>

<style scoped>

</style>
