<script>
import TwListGroupItem from '@/components/generic/list-group/tw-list-group-item.vue'
import TwButton from '@/components/generic/tw-button.vue'
import TwTransitionContainer from '@/components/generic/tw-transition-container.vue'

export default {
  name: 'FundraiserTeamListGroupItem' ,
  components: { TwTransitionContainer, TwButton, TwListGroupItem },
  props: {
    team: {
      type: Object,
      required: true
    },
    alwaysExpanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Expanded: false,
    }
  },
  methods: {
    toggleExpanded() {
      if(this.alwaysExpanded) return
      this.Expanded = !this.Expanded
    }
  }
}
</script>

<template>
  <tw-list-group-item>
    <div class="grid grid-cols-4" @click="toggleExpanded">
      <div :class="[{'cursor-pointer': !alwaysExpanded},' col-span-3']">
        <icon v-if="!alwaysExpanded" :icon="Expanded? 'minus':'plus'" /> {{ team.name }}
      </div>
      <div>
        {{ team.raised }}
      </div>
    </div>
    <tw-transition-container>
      <div v-if="Expanded || alwaysExpanded" class=" mt-2">
        <div v-if="team.cached && team.cached.members && team.cached.members.length > 0">
          <div v-for="member in team.cached.members" :key="'team-member-' + team.id + '-' + member.id" class="grid grid-cols-4">
            <div class="col-span-3">
              {{ member.name }}
            </div>
            <div class="text-right">
              {{ member.raised_formatted }}
            </div>
          </div>
        </div>
        <div v-else>
          No donations yet.
        </div>
      </div>
    </tw-transition-container>
  </tw-list-group-item>
</template>

<style scoped>

</style>
