import axios from 'axios'
export default {
  props: {
    uploadUrl: {
      type: String,
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    gallery: {
      type: String,
      required: false,
      default: 'global'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    fileTypes: {
      type: Array,
      required: false,
      default: () => null
    },
    allowPasteImageUpload: {
      type: Boolean,
      default: false
    },
    imagesOnly: {
      type: Boolean,
      default:false
    },
    postFields: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    postUrlComputed () {
      if (this.uploadUrl) {
        return this.uploadUrl
      } else {
        return '/api/v1/upload'
      }
    },
    uid () {
      return this._uid
    },
    accept () {
      if(this.imagesOnly) {
        return ['image/jpeg', 'image/png','image/webp'].join(',')
      }
      if (this.fileTypes && Array.isArray(this.fileTypes) && this.fileTypes.length > 0) {
        return this.fileTypes.join(',')
      } else {
        return null
      }
    }
  },
  data () {
    return {
      UploadError: null,
      LoadingUploading: false,
      ShowPasteField: false,
      CancelTokenSource: null,
      UploadPercentage: 0,
      FormData: null
    }
  },
  emits: ['uploaded', 'uploadedImage','input','update:modelValue'],
  methods: {
    cancelUpload () {
      this.CancelTokenSource.cancel('Upload cancelled')
    },
    async pastedImage (e) {
      console.log('pasted', e)
      if (e.clipboardData) {
        const items = e.clipboardData.items
        if (!items) return

        // access data directly
        let isImage = false
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            // image
            const blob = items[i].getAsFile()
            // let URLObj = window.URL || window.webkitURL
            // let source = URLObj.createObjectURL(blob)
            isImage = true
            await this.uploadPastedImage(blob)
          }
        }
        if (isImage === true) {
          e.preventDefault()
        }
      }
    },
    async uploadPastedImage (blob) {
      this.Loading = true
      const formData = new FormData()
      formData.append('file', blob)
      const { data } = await axios.post('/api/upload-blob-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.Loading = false
      this.Upload = data

      this.$emit('uploadedImage', data)
      this.$emit('uploaded', data)
    },
    buildFormData() {
      const formData = new FormData()
      for(const key in this.postFields) {
        formData.append(key, this.postFields[key])
      }
      return formData
    },
    async syncFile () {
      try {
        this.UploadError = null
        const files = this.$refs.uploadableField.files
        this.FormData = this.buildFormData()
        this.FormData.append('multiple', this.multiple)
        if (this.multiple) {
          for (let i = 0; i < files.length; i++) {
            this.FormData.append('files[' + i + ']', files[i])
          }
        } else {
          this.FormData.append('file', files[0])
        }
        if (this.gallery) {
          this.FormData.append('available_in_gallery', this.gallery)
        }
        this.LoadingUploading = true
        this.CancelTokenSource = axios.CancelToken.source()
        const { data } = await axios.post(this.postUrlComputed, this.FormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          cancelToken: this.CancelTokenSource.token,
          onUploadProgress: function (progressEvent) {
            this.UploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }.bind(this)
        })
        const input = this.$refs.uploadableField
        input.type = 'text'
        input.type = 'file'
        this.UploadPercentage = 0

        this.$emit('input', data)
        this.$emit('uploaded', data)
        this.$emit('update:modelValue', data)

        this.FormData = null
        this.LoadingUploading = false
      } catch (e) {
        console.error(e)
        if(e.response) {
          this.UploadError = e.response.data
        }
        const input = this.$refs.uploadableField
        input.type = 'text'
        input.type = 'file'
        this.CancelTokenSource = null
        this.UploadPercentage = 0
        this.LoadingUploading = false
      }
    }
  }
}
