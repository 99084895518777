<script>

export default {
  name: 'TwListGroupItem',

}
</script>

<template>
  <li class="py-4">
    <slot />
  </li>
</template>

<style scoped>

</style>
