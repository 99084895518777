import { defineStore } from 'pinia'
import { actions, getters, state } from './ext/items.js'
import axios from 'axios'


export const useDonationsStore = defineStore('donations', {
  id: 'donation',
  state: () => ({
    items: [],
    api: '/api/v1/donations'
  }),
  getters: {
    itemsByFundraiserId: (state) => (fundraiserId) => {
      return state.items.filter(item => item.fundraiser_id === fundraiserId)
    },
    ...getters
  },
  actions: {
    async fetchItemsByFundraiserId(fundraiserId) {
      const { data } = await axios.get(`${this.api}`, {
        params: {
          fundraiser_id: fundraiserId,
          not_paginated: true
        }
      })
      this.items = data.data
      return data
    },
    ...actions
  }
})
