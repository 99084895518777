<script>
import TwTransitionContainer from '@/components/generic/tw-transition-container.vue'
import TwNotification from '@/components/generic/tw-notification.vue'
import TwModal from '@/components/generic/modal/tw-modal.vue'
import modalCascadeMixin from '@/mixins/modalCascadeMixin.js'
import TwButton from '@/components/generic/tw-button.vue'
import TwNumber from '@/components/generic/forms/tw-number.vue'

export default {
  name: 'DonationAppFeeChangeModal' ,
  components: { TwNumber, TwButton, TwModal, TwNotification, TwTransitionContainer },
  mixins: [modalCascadeMixin],
  props:{
    modelValue: {
      type: [Number],
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      ShowAppFeeEditor: false,
    }
  },
  methods: {
    saveAndClose() {
      this.close()
    }
  }
}
</script>

<template>
  <tw-modal ref="modal" no-footer title="Optional Contributions" size="sm" ok-text="Sounds Good">
    <div class="text-sm grid gap-4">
      <p> Unlike many other software providers, GroupRev is entirely free for fundraisers to use.  </p>
      <p>Normally, an individual will pay a large service free on top of fees charged by credit card companies! This means an individual is lucky to get 90% of their money. </p>
      <p>GroupRev charges zero service fee to the fundraiser.Not only that, but we walk alongside people as they fundraise and help make their fundraising efforts successful. </p>
      <p>We do this at no cost to the them. How do we do that? We cover the costs of servers, security, social tools and maintenance of the software by micro contributions from people like yourself. </p>
      <p>A small amount goes a long way in giving people the best tools in the world to fundraise.We set the optional contribution at 5% but you can set it as high as you want so we can help more people.</p>
      <tw-transition-container>
        <div v-if="ShowAppFeeEditor" class="flex flex-row justify-center gap-4 mt-4">
          <div> <tw-number :model-value="modelValue" label="Enter optional percentage:" append="%" @update:model-value="$emit('update:modelValue', $event)" /> </div>
          <div class="pt-8 flex flex-row gap-3">
            <div>
              <tw-button variant="secondary" @click="close()">
                Cancel
              </tw-button>
            </div>
            <div>
              <tw-button variant="primary" @click="saveAndClose">
                Save and Close
              </tw-button>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-row justify-center gap-4 mt-4">
          <tw-button size="lg" variant="primary" @click="close()">
            Sounds Good
          </tw-button>
          <tw-button size="lg" variant="secondary" @click="ShowAppFeeEditor = true">
            Change Optional Amount
          </tw-button>
        </div>
      </tw-transition-container>
    </div>
    <tw-notification>
      <div class="text-center">
        <p> Optional contribution saved! </p>
      </div>
    </tw-notification>
  </tw-modal>
</template>

<style scoped>

</style>
