<script>
export default {
  name: 'TwContainer'
}
</script>

<template>
  <div class="container mx-auto mt-4 p-4">
    <slot />
  </div>
</template>

<style scoped>

</style>
