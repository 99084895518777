import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faPlus, faPlusSquare, faMinusSquare, faMinus, faDollar, faUpRightFromSquare, faUpRightAndDownLeftFromCenter, faSquareArrowUpRight, faSquareCheck, faFilePen, faFileLines,
  faBars, faUser, faHome, faHouseUser, faLock, faSignOutAlt, faCog, faArrowAltCircleRight, faFilePdf, faSpinner, faChevronRight, faChevronLeft, faChevronUp, faChevronDown, faEdit, faUserEdit, faTimes, faSave, faSquare, faCheckSquare, faCheckCircle, faEnvelopeSquare, faEnvelope, faEnvelopeOpen
} from '@fortawesome/free-solid-svg-icons'

import {
  faGithub, faFacebook, faLinkedin, faLinkedinIn, faFacebookSquare, faFacebookMessenger, faGoogle, faGooglePlus, faGooglePlusG, faWhatsapp, faWhatsappSquare, faTwitter, faTwitterSquare
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faBars, faPlus, faPlusSquare, faMinusSquare, faMinus, faLinkedin, faLinkedinIn, faDollar, faUpRightFromSquare, faUpRightAndDownLeftFromCenter, faSquareArrowUpRight, faSquareCheck,
  faUser, faHome, faHouseUser, faLock, faSignOutAlt, faCog, faGithub, faArrowAltCircleRight, faFilePdf, faSpinner, faChevronRight, faWhatsapp, faWhatsappSquare, faTwitter, faTwitterSquare,
  faChevronLeft, faChevronUp, faChevronDown, faEdit, faUserEdit, faTimes, faSave, faFacebook, faFacebookMessenger, faFilePen, faFileLines,
  faFacebookSquare, faGoogle, faGooglePlus, faGooglePlusG, faSquare, faCheckSquare, faCheckCircle, faEnvelopeSquare, faEnvelope, faEnvelopeOpen
)

export default library

