export const state = () => ({
  items: [],
  api: '/api/v1/items'
})
export const getters = {
  getItemById(state) {
    return (itemId) => state.items.find(item => item.id === itemId)
  },
  getItems(state) {
    return state.items
  }
}

export const actions = {
  itemPatchState(item) {
    const index = this.items.findIndex(itemLoop => itemLoop.id === item.id)
    if (index > -1) {
      this.items.splice(index, 1, item)
    } else {
      this.items.push(item)
    }
  },
  itemRemoveFromState(itemId) {
    const index = this.items.findIndex(itemLoop => itemLoop.id === itemId)
    if (index > -1) {
      this.items.splice(index, 1)
    }
  },
  async fetchItemById(itemId) {
    const { data } = await axios.get(`${this.api}/${itemId}`)
    this.itemPatchState(data)
    return data
  },
  async fetchItems(params) {
    const { data } = await axios.get(this.api,{
      params: params
    })
    if(params && params.clear) {
      this.items = data.data
    }
    else {
      for (const item of data.data) {
        this.itemPatchState(item)
      }
    }
    return data
  },
  async itemCreate(payload) {
    const { data } = await axios.post(this.api, payload)
    this.itemPatchState(data)
    return data
  },
  async itemPatch(payload) {
    const { data } = await axios.patch(this.api + '/' + payload.id, payload)
    this.itemPatchState(data)
    return data
  },
  async itemDelete(itemId) {
    const { data } = await axios.delete(this.api + '/' + itemId)
    this.itemRemoveFromState(itemId)
    return data
  },
  clearItems() {
    this.items = []
  }
}
