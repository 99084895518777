import { useFundraiserPublicStore } from '@/stores/fundraiserPublic.js'
import fetchDataMixin from '@/mixins/fetchDataMixin.js'

export default {
  mixins:[fetchDataMixin],
  props: {
    fundraiserId: {
      type: Number,
      required: true
    }
  },
  computed: {
    fundraiser() {
      return useFundraiserPublicStore().getItemById(this.fundraiserId)
    }
  },
  mounted() {
    this.fetchFundraiser()
  },
  methods: {
    async fetchFundraiser() {
      await this.fetchData(useFundraiserPublicStore().fetchItemById, this.fundraiserId)
      document.title = this.fundraiser.title + ' - ' + document.title
    }
  }
}
