const OrganizationsIndexPage = () => import('@/pages/organizations/OrganizationsIndexPage.vue')
const OrganizationEditPage = () => import('@/pages/organizations/OrganizationEditPage.vue')
const OrganizationDetailsPage = () => import('@/pages/organizations/organization-edit/OrganizationDetailsPage.vue')
const OrganizationPaymentSetupPage = () => import('@/pages/organizations/organization-edit/OrganizationPaymentSetupPage.vue')

export default [
  { path: "/organizations", name: 'OrganizationsIndexPage', component: OrganizationsIndexPage },
  {
    path: "/organizations/:organizationId",
    name: 'OrganizationEditPage',
    redirect: { name: 'OrganizationDetailsPage' },
    component: OrganizationEditPage,
    props: route => ({ organizationId: parseInt(route.params.organizationId) }),
    children: [
      { path:'', name:'OrganizationDetailsPage', component: OrganizationDetailsPage, props: route => ({ organizationId: parseInt(route.params.organizationId) }) },
      { path:'payment-setup', name:'OrganizationPaymentSetupPage', component: OrganizationPaymentSetupPage, props: route => ({ organizationId: parseInt(route.params.organizationId) }) },
      { path:'payment-setup/finish-setup/:paymentServiceId', name:'OrganizationPaymentSetupPageFinishSetup', component: OrganizationPaymentSetupPage, props: route => ({ paymentServiceId: parseInt(route.params.paymentServiceId), organizationId: parseInt(route.params.organizationId) }) }
    ],
  },
]
