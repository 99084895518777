import { defineStore } from 'pinia'
import { actions, getters, state } from './ext/items.js'
import axios from 'axios'


export const useFundraiserStore = defineStore('fundraiser', {
  id: 'fundraisers',
  state: () => ({
    items: [],
    api: '/api/v1/fundraisers'
  }),
  getters: {
    getItemById(state) {
      return (itemId) => state.items.find(item => item.id === itemId)
    },
    getItems(state) {
      return state.items
    },
    getItemsFeatured(state) {
      return state.items.filter(item => item.featured)
    },
    getItemsByParentId(state) {
      return (parentId) => state.items.filter(item => item.parent_fundraiser_id === parentId)
    },
  },
  actions: {
    ...actions,
    async fetchFeaturedItems(params) {
      const { data } = await axios.get(this.api, {
        params: {
          featured: true,
          not_paginated: true,
          ...params
        }
      })
      this.items = data.data
    },
    async fundraiserDetailsPatch(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/details', payload)
      this.itemPatchState(data)
      return data
    },
    async fundraiserCommunicationPatch(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/communication', payload)
      this.itemPatchState(data)
      return data
    },
    async fundraiserBackgroundPatch(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/background-image', payload)
      this.itemPatchState(data)
      return data
    },
    async fundraiserBannerPatch(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/banner-image', payload)
      this.itemPatchState(data)
      return data
    },
    async fundraiserSlideShowPatch(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/slideshow', payload)
      this.itemPatchState(data)
      return data
    },
    async fundraiserLogoPatch(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/logo', payload)
      this.itemPatchState(data)
      return data
    },
    async fundraiserValidateSlug(payload) {
      const { data } = await axios.post(this.api + '/' + payload.id + '/validate-slug', payload)
      return data
    },
    async fundraiserPublish(fundraiserId) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/publish')
      this.itemPatchState(data)
      return data
    },
    async fundraiserUnPublish(fundraiserId) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/unpublish')
      this.itemPatchState(data)
      return data
    },
    async fundraiserSettingsPatch(fundraiserId, settings) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/settings', settings)
      this.itemPatchState(data)
      return data
    },
    async fundraiserJoin(fundraiserId, fundraiser) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/children', fundraiser)
      this.itemPatchState(data)
      return data
    },
    async postUpdate(fundraiserId, payload) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/updates', payload)
      // this.itemPatchState(data)
      return data
    },
    async fundraiserMarkAsFeatured(fundraiserId) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/mark-as-featured')
      this.itemPatchState(data)
      return data
    },
    async fundraiserMarkAsNotFeatured(fundraiserId) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/mark-as-featured')
      this.itemPatchState(data)
      return data
    },
  }
})
