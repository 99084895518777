import { defineStore } from 'pinia'
import { actions, getters, state } from './ext/items.js'
import axios from 'axios'


export const useFundraiserPublicStore = defineStore('fundraisersPublic', {
  id: 'fundraisersPublic',
  state: () => ({
    items: [],
    api: '/api/v1/fundraisers-public'
  }),
  getters: {
    getItemById(state) {
      return (itemId) => state.items.find(item => item.id === itemId)
    },
    getItems(state) {
      return state.items
    },
    getItemsFeatured(state) {
      return state.items.filter(item => item.featured)
    },
    getItemsByParentId(state) {
      return (parentId) => state.items.filter(item => item.parent_fundraiser_id === parentId)
    },
  },
  actions: {
    ...actions,
    async startDonation(payload) {
      const { data } = await axios.post(this.api + '/' + payload.fundraiser_id + '/donations', payload)
      return data
    },
    async confirmDonation(payload) {
      const { data } = await axios.post(this.api + '/' + payload.fundraiser_id + '/donations/' + payload.donation_id + '/confirm', payload)
      return data
    },
    async subscribeToFundraiser(fundraiserId, payload) {
      const { data } = await axios.post(this.api + '/' + fundraiserId + '/subscribers', payload)
      return data
    }
  }
})
