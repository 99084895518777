<script>
import FundraiserSlideshow from '@/components/fundraisers/fundraiser-slideshow.vue'
import FundraiserDonate from '@/components/fundraisers/fundraiser-donate.vue'
import TwCard from '@/components/generic/tw-card.vue'
import FundraiserTabs from '@/components/fundraisers/fundraiser-tabs.vue'

export default {
  name: 'FundraiserLeftColumn',
  components: { FundraiserTabs, TwCard, FundraiserDonate, FundraiserSlideshow },
  props: {
    fundraiser: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div>
    <h1 class="text-5xl text-center">
      {{ fundraiser.title }}
    </h1>
    <p class="text-center text-xl text-gray-700 dark:text-gray-400 mb-6">
      {{ fundraiser.short_description }}
    </p>
    <fundraiser-slideshow v-if="fundraiser.slideshow && fundraiser.slideshow.slides.length > 0" class="mb-4 mt-4" :slideshow="fundraiser.slideshow" />
    <fundraiser-donate :fundraiser="fundraiser" />
    <div class="text-2xl text-center mt-10">
      About this Campaign
    </div>
    <tw-card>
      <fundraiser-tabs :fundraiser="fundraiser" />
    </tw-card>
  </div>
</template>

<style scoped>

</style>
