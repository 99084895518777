<script>
export default {
  name: 'TwInput',
  props: {
    modelValue: {
      type: [String,Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue', 'update', 'blur'],
}
</script>

<template>
  <div class="w-full mb-2">
    <label v-if="label" :for="id" class="block text-sm font-medium leading-6 text-gray-900 mb-1"> {{ label }} </label>
    <input
      :id="id" :required="required" :readonly="disabled" :value="modelValue" type="text" :name="id" :autocomplete="id"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" :placeholder="placeholder" @blur="$emit('blur')"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </div>
</template>

<style scoped>

</style>
