export default {
  props: {
    modelValue: {
      type: [String,Number,Object],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(7)
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: null
    },
    valueKey: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      SelectedIndex: null
    }
  },
  watch: {
    SelectedIndex() {
      this.selected()
    },
    options(newVal) {
      this.processModelValue()
    },
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.processModelValue()
      }
    }
  },
  methods: {
    processModelValue() {
      if(this.modelValue) {
        if(this.valueKey) {
          this.SelectedIndex = this.options.findIndex(option => option[this.valueKey] === this.modelValue)
        }
        else {
          this.SelectedIndex = this.options.findIndex(option => option === this.modelValue)
        }
      }
    },
    selected() {
      if(this.SelectedIndex > -1) {
        this.$emit('update:modelValue', this.valueKey ? this.options[this.SelectedIndex][this.valueKey] : this.options[this.SelectedIndex])
      }
    }
  }
}
