<script>
import TwModal from '@/components/generic/modal/tw-modal.vue'
import TwInput from '@/components/generic/forms/tw-input.vue'
import TwValidationErrors from '@/components/generic/tw-validation-errors.vue'
import TwAmount from '@/components/generic/forms/tw-amount.vue'
import TwWysiwyg from '@/components/generic/forms/tw-wysiwyg.vue'
import { useTeamsStore } from '@/stores/teams.js'
import modalCascadeMixin from '@/mixins/modalCascadeMixin.js'

export default {
  name: 'TeamEditModal',
  components: { TwWysiwyg, TwAmount, TwValidationErrors, TwInput, TwModal },
  mixins:[modalCascadeMixin],
  props: {
    team: {
      type: Object,
      default: null
    },
    fundraiser: {
      type: Object,
      required: true
    }
  },
  emits: ['updated', 'created'],
  data() {
    return {
      Loading: false,
      ErrorValidationMessage: null,
      TeamLocal: {
        fundraiser_id: this.fundraiser.id,
        ...(this.team && {id: this.team.id}),
        name: this.team? this.team.name: '',
        goal: this.team? this.team.goal: '',
        description: this.team? this.team.description: '',
        slug: this.team? this.team.slug: ''
      }
    }
  },
  methods: {
    opened() {
      // this.$refs.name.focus()
    },
    async saveTeam() {
      try {
        this.ErrorValidationMessage = null
        this.Loading = true
        if(this.team) {
          const team = await useTeamsStore().itemPatch(this.TeamLocal)
          this.$emit('updated', team)
        } else {
          const team = await useTeamsStore().itemCreate(this.TeamLocal)
          this.$emit('created', team)
          this.TeamLocal = {
            fundraiser_id: this.fundraiser.id,
            name: '',
            goal: '',
            description: '',
            slug: ''
          }
        }
        this.$refs.modal.close()
        this.Loading = false
      } catch (e) {
        this.ErrorValidationMessage = e.response? e.response.data: null
        this.Loading = false
        throw e
      }
    }
  }
}
</script>

<template>
  <tw-modal ref="modal" :modal-id="'team-edit-modal-' + (team? team.id: 'create')" :title="team? 'Edit team ' + team.name:'Create a new team for ' + fundraiser.title" @opened="opened" @ok="saveTeam">
    <tw-input ref="name" v-model="TeamLocal.name" label="Team name" />
    <tw-amount v-model="TeamLocal.goal" label="Team Goal" />
    <tw-wysiwyg v-model="TeamLocal.description" label="Team Description" />
    <tw-validation-errors :validation-result-error="ErrorValidationMessage" />
    <template #ok>
      <span v-if="team">
        Update <span class="font-semibold">{{ team.name }}</span>
      </span>
      <span v-else>
        Create Team for <span class="font-semibold">{{ fundraiser.title }}</span>
      </span>
    </template>
  </tw-modal>
</template>

<style scoped>

</style>
